import { map } from 'rxjs/operators'
import { Injectable } from '@angular/core'
import { GlobalsService } from './globals.service'
import { AuthService } from './auth.service'
import { HttpClient } from '@angular/common/http'

@Injectable({
    providedIn: 'root'
})
export class RatingsService {
    jwt = null
    requestOptions = null

    constructor(private httpClient: HttpClient, private globalsService: GlobalsService) {}

    rateUser(
        missionId,
        fromId,
        toId,
        userRating,
        punctualityRating,
        appearanceRating,
        behaviorRating,
        skillsRating,
        message
    ) {
        const data = {
            missionId,
            fromId,
            toId,
            userRating,
            punctualityRating,
            appearanceRating,
            behaviorRating,
            skillsRating,
            message
        }
        console.log(JSON.stringify(data))
        return this.httpClient.put(this.globalsService.apiUrl + '/ratings/rate/user', data).pipe(
            map((response: any) => {
                return response
            })
        )
    }

    rateCompany(fromId, toId, rating) {
        const data = {
            fromId,
            toId,
            rating
        }
        return this.httpClient.put(this.globalsService.apiUrl + '/ratings/rate/company', data).pipe(
            map((response: any) => {
                return response
            })
        )
    }

    // getUserRaitingForProfile(userId, profileId) {
    //     return this.httpClient.get(this.globalsService.apiUrl + '/ratings/profile/' + userId + '/' + profileId, )
    //         .pipe(map((response: any) => {
    //             return response;
    //         });
    // }

    getUserRaitingForCompany(userId, companyId) {
        return this.httpClient
            .get(this.globalsService.apiUrl + '/ratings/company/' + userId + '/' + companyId)
            .pipe(
                map((response: any) => {
                    return response
                })
            )
    }
}
