import { Injectable } from '@angular/core'
import { DeviceInfo, Plugins } from '@capacitor/core'
const { Device } = Plugins

@Injectable({
    providedIn: 'root'
})
export class DeviceService {
    deviceInfo: DeviceInfo

    constructor() {}

    async getInfos() {
        if (!this.deviceInfo) {
            this.deviceInfo = await Device.getInfo()
        }
        return this.deviceInfo
    }
}
