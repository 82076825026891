import { HttpClient } from '@angular/common/http'
import { Injectable } from '@angular/core'
import * as moment from 'moment'
import { map } from 'rxjs/operators'
import { GlobalsService } from './globals.service'
import { UserService } from './user.service'

@Injectable({
    providedIn: 'root'
})
export class ProfilesService {
    requestOptions = null

    constructor(
        private httpClient: HttpClient,
        private globalsService: GlobalsService,
        private userService: UserService
    ) {}

    prefixes() {
        return this.httpClient
            .get(this.globalsService.apiUrl + '/profiles/prefixes')
            .pipe(
                map((response: any) => {
                    return response
                })
            )
    }

    languages() {
        return this.httpClient
            .get(this.globalsService.apiUrl + '/profiles/languages')
            .pipe(
                map((response: any) => {
                    return response
                })
            )
    }

    toggleLanguage(language) {
        const data = {
            userId: this.userService.data.id,
            languageId: language.id,
            isOk: language.isOk
        }
        return this.httpClient
            .post(
                this.globalsService.apiUrl + '/profiles/languages/toggle',
                data
            )
            .pipe(
                map((response: any) => {
                    return response.rows
                })
            )
    }

    contracts() {
        return this.httpClient
            .get(this.globalsService.apiUrl + '/profiles/contracts')
            .pipe(
                map((response: any) => {
                    return response
                })
            )
    }

    toggleContract(contract) {
        const data = {
            userId: this.userService.data.id,
            contractId: contract.id,
            isOk: contract.isOk
        }
        return this.httpClient
            .post(
                this.globalsService.apiUrl + '/profiles/contracts/toggle',
                data
            )
            .pipe(
                map((response: any) => {
                    return response.rows
                })
            )
    }

    newAccount(data) {
        return this.httpClient
            .post(this.globalsService.apiUrl + '/register', data)
            .pipe(
                map((response: any) => {
                    return response
                })
            )
    }

    byId(id) {
        return this.httpClient
            .get(this.globalsService.apiUrl + '/profiles/' + id)
            .pipe(
                map((response: any) => {
                    return response
                })
            )
    }

    isEmailAlreadyUsed(email) {
        return this.httpClient
            .get(
                this.globalsService.apiUrl +
                    '/profiles/isEmailAlreadyUsed/' +
                    this.userService.data.id +
                    '/' +
                    email
            )
            .pipe(
                map((response: any) => {
                    return response
                })
            )
    }

    all(pipes, keywords) {
        const data = {
            pipes,
            keywords
        }
        return this.httpClient
            .post(this.globalsService.apiUrl + '/profiles', data)
            .pipe(
                map((response: any) => {
                    return response.rows
                })
            )
    }

    allExceptMine(pipes, keywords, showAll, limit) {
        const data = {
            pipes,
            keywords,
            userId: this.userService.data.id,
            showAll,
            limit
        }
        return this.httpClient
            .post(this.globalsService.apiUrl + '/profiles/allExceptMine', data)
            .pipe(
                map((response: any) => {
                    return response.rows
                })
            )
    }

    filtered(pipes, keywords, activitiesTagsIds, limit, nextItems) {
        const data = {
            pipes,
            keywords,
            activitiesTagsIds,
            userId: this.userService.data.id,
            limit,
            nextItems
        }
        return this.httpClient
            .post(
                this.globalsService.apiUrl + '/profiles/filtered/nested',
                data
            )
            .pipe(
                map((response: any) => {
                    return this.globalsService.renameEmptyColumn(response.rows)
                })
            )
    }

    allNoGeoloc(pipes, keywords) {
        const data = {
            pipes,
            keywords
        }
        return this.httpClient
            .post(this.globalsService.apiUrl + '/profiles/nogeoloc', data)
            .pipe(
                map((response: any) => {
                    return response.rows
                })
            )
    }

    allAround(pipes, keywords, latitude, longitude, km, showAll, hubId, limit) {
        const data = {
            userId: this.userService.data.id,
            pipes,
            keywords,
            latitude,
            longitude,
            km,
            showAll,
            hubId,
            limit
        }
        return this.httpClient
            .post(this.globalsService.apiUrl + '/profiles/all/near', data)
            .pipe(
                map((response: any) => {
                    return response.rows
                })
            )
    }

    search(ringId, searchName) {
        const data = {
            userId: this.userService.data.id,
            ringId,
            searchName
        }
        return this.httpClient
            .post(this.globalsService.apiUrl + '/profiles/search', data)
            .pipe(
                map((response: any) => {
                    return response.rows
                })
            )
    }

    updateLastConnection(id) {
        const timestamp = moment().format('YYYY-MM-DD HH:mm:ss')
        const data = {
            timestamp,
            userId: id
        }
        return this.httpClient
            .patch(this.globalsService.apiUrl + '/profile/lastConnection', data)
            .pipe(
                map((response: any) => {
                    return response
                })
            )
    }

    swipe(profileId, choice) {
        const data = {
            userId: this.userService.data.id,
            profileId,
            choice
        }
        return this.httpClient
            .put(this.globalsService.apiUrl + '/profile/swipe', data)
            .pipe(
                map((response: any) => {
                    return response
                })
            )
    }

    allWithSpecificTag(tagId) {
        return this.httpClient
            .get(
                this.globalsService.apiUrl +
                    '/profiles/tag/' +
                    tagId +
                    '/' +
                    this.userService.data.id
            )
            .pipe(
                map((response: any) => {
                    return response.rows
                })
            )
    }

    sendActivationEmail() {
        return this.httpClient
            .get(
                this.globalsService.apiUrl +
                    '/register/resend/' +
                    this.userService.data.email
            )
            .pipe(
                map((response: any) => {
                    return response
                })
            )
    }

    sendPassword(email) {
        return this.httpClient
            .get(this.globalsService.apiUrl + '/password/' + email)
            .pipe(
                map((response: any) => {
                    return response
                })
            )
    }

    deleteUser(userId) {
        return this.httpClient
            .delete(this.globalsService.apiUrl + '/user/' + userId)
            .pipe(
                map((response: any) => {
                    return response
                })
            )
    }

    changePassword(data) {
        return this.httpClient
            .patch(this.globalsService.apiUrl + '/user/password', data)
            .pipe(
                map((response: any) => {
                    return response
                })
            )
    }

    resetSwipes(table) {
        if (table == 'Missions') {
            return this.httpClient
                .delete(
                    this.globalsService.apiUrl +
                        '/swipes/reset/missions/' +
                        this.userService.data.id
                )
                .pipe(
                    map((response: any) => {
                        return response
                    })
                )
        } else if (table == 'Profiles') {
            return this.httpClient
                .delete(
                    this.globalsService.apiUrl +
                        '/swipes/reset/profiles/' +
                        this.userService.data.id
                )
                .pipe(
                    map((response: any) => {
                        return response
                    })
                )
        } else if (table == 'Companies') {
            return this.httpClient
                .delete(
                    this.globalsService.apiUrl +
                        '/swipes/reset/companies/' +
                        this.userService.data.id
                )
                .pipe(
                    map((response: any) => {
                        return response
                    })
                )
        }
    }

    updateProfile(data) {
        console.log('log: ProfilesService -> updateProfile -> data', data)
        return this.httpClient
            .patch(this.globalsService.apiUrl + '/user/profile', data)
            .pipe(
                map((response: any) => {
                    return response
                })
            )
    }

    updateUserProfile() {
        const data = {
            id: this.userService.data.id,
            data: {
                hub_reference: this.userService.data.hubId,
                root_channel: this.userService.data.rootChannelId,
                prefix_id: this.userService.data.prefixId,
                current_profile_id: this.userService.data.currentProfile,
                firstname: this.userService.data.firstname,
                lastname: this.userService.data.lastname,
                email: this.userService.data.email,
                dob: this.userService.data.dob,
                photo_url: this.userService.data.photo_url,
                photo_social: this.userService.data.photo_social,
                network: this.userService.data.network,
                phone: this.userService.data.phone,
                cellphone: this.userService.data.cellphone,
                website: this.userService.data.website,
                job: this.userService.data.job,
                job_from: this.userService.data.job_from,
                price_amount: this.userService.data.price_amount,
                price_period_id: this.userService.data.price_periodId,
                address: this.userService.data.address,
                zip: this.userService.data.zip,
                city: this.userService.data.city,
                latitude: this.userService.data.latitude,
                longitude: this.userService.data.longitude,
                search_radius_id: this.userService.searchZoneId,
                search_local_radius: this.userService.data.search_local_radius
            }
        }
        return this.httpClient
            .patch(this.globalsService.apiUrl + '/user/profile/full', data)
            .pipe(
                map((response: any) => {
                    return response
                })
            )
    }

    updateAdress(data) {
        return this.httpClient
            .patch(this.globalsService.apiUrl + '/user/profile/address', data)
            .pipe(
                map((response: any) => {
                    return response
                })
            )
    }

    updatePhotoSocialUrl(data) {
        return this.httpClient
            .patch(
                this.globalsService.apiUrl + '/user/profile/photo/social',
                data
            )
            .pipe(
                map((response: any) => {
                    return response
                })
            )
    }

    syncPhotoFromSocial(userId) {
        const data = {
            userId
        }
        return this.httpClient
            .patch(
                this.globalsService.apiUrl + '/user/profile/photo/social/sync',
                data
            )
            .pipe(
                map((response: any) => {
                    return response
                })
            )
    }

    getAllTagsFromUser() {
        return this.httpClient
            .get(
                this.globalsService.apiUrl +
                    '/tags/user/' +
                    this.userService.data.id
            )
            .pipe(
                map((response: any) => {
                    return response
                })
            )
    }

    // addNewTags(arrayOfTags) {
    //     return this.httpClient.post(this.globalsService.apiUrl + '/tags/add', arrayOfTags).then(function(res) {
    //         return res;
    //     });
    // },

    removeAllTags() {
        return this.httpClient
            .delete(
                this.globalsService.apiUrl +
                    '/tags/user/' +
                    this.userService.data.id
            )
            .pipe(
                map((response: any) => {
                    return response
                })
            )
    }

    // associateTagsWithProfile(arrayOfTags) {
    //     return this.httpClient.post(this.globalsService.apiUrl + '/tags/add/user/' + this.userService.data.id, arrayOfTags, )
    //         .map((response: any) => {
    //             return response;
    //         });
    // }

    clearPhoto() {
        return this.httpClient
            .patch(
                this.globalsService.apiUrl + '/user/profile/photo',
                this.userService.data
            )
            .pipe(
                map((response: any) => {
                    return response
                })
            )
    }

    getBioMini() {
        return this.httpClient
            .get(
                this.globalsService.apiUrl +
                    '/user/bio/mini/' +
                    this.userService.data.id
            )
            .pipe(
                map((response: any) => {
                    return response.rows
                })
            )
    }

    saveBioMini(bioMini) {
        const data = {
            userId: this.userService.data.id,
            bioMini
        }
        return this.httpClient
            .put(this.globalsService.apiUrl + '/user/bio/mini', data)
            .pipe(
                map((response: any) => {
                    return response
                })
            )
    }

    updateReferentHub(hubId) {
        const data = {
            userId: this.userService.data.id,
            hubId
        }
        return this.httpClient
            .patch(this.globalsService.apiUrl + '/profile/referentHub', data)
            .pipe(
                map((response: any) => {
                    return response
                })
            )
    }

    updateJob(job) {
        const data = {
            userId: this.userService.data.id,
            job
        }
        return this.httpClient
            .patch(this.globalsService.apiUrl + '/profile/job', data)
            .pipe(
                map((response: any) => {
                    return response
                })
            )
    }

    updateMainJob(hubId, job, year) {
        const data = {
            userId: this.userService.data.id,
            hubId,
            job,
            year
        }
        console.log(JSON.stringify(data))
        return this.httpClient
            .patch(this.globalsService.apiUrl + '/profile/mainJob', data)
            .pipe(
                map((response: any) => {
                    return response
                })
            )
    }

    updatePrice(amount, periodId) {
        const data = {
            userId: this.userService.data.id,
            amount,
            periodId
        }
        return this.httpClient
            .patch(this.globalsService.apiUrl + '/profile/price', data)
            .pipe(
                map((response: any) => {
                    return response
                })
            )
    }

    updateCurrentProfile(currentProfileId) {
        const data = {
            currentProfileId,
            userId: this.userService.data.id
        }
        return this.httpClient
            .patch(this.globalsService.apiUrl + '/profile/currentProfile', data)
            .pipe(
                map((response: any) => {
                    return response
                })
            )
    }

    toggleIsPrivate(flag) {
        const data = {
            userId: this.userService.data.id,
            flag
        }
        return this.httpClient
            .patch(
                this.globalsService.apiUrl + '/profile/isPrivate/toggle',
                data
            )
            .pipe(
                map((response: any) => {
                    return response
                })
            )
    }

    toggleIsProvider(flag) {
        const data = {
            userId: this.userService.data.id,
            flag
        }
        return this.httpClient
            .patch(
                this.globalsService.apiUrl + '/profile/isProvider/toggle',
                data
            )
            .pipe(
                map((response: any) => {
                    return response
                })
            )
    }

    toggleShowRating(flag) {
        const data = {
            userId: this.userService.data.id,
            flag
        }
        return this.httpClient
            .patch(
                this.globalsService.apiUrl + '/profile/showRating/toggle',
                data
            )
            .pipe(
                map((response: any) => {
                    return response
                })
            )
    }

    toggleShowMissionsBadge(flag) {
        const data = {
            userId: this.userService.data.id,
            flag
        }
        return this.httpClient
            .patch(
                this.globalsService.apiUrl +
                    '/profile/showMissionsBadge/toggle',
                data
            )
            .pipe(
                map((response: any) => {
                    return response
                })
            )
    }

    toggleShowCandidaturesBadge(flag) {
        const data = {
            userId: this.userService.data.id,
            flag
        }
        return this.httpClient
            .patch(
                this.globalsService.apiUrl +
                    '/profile/showCandidaturesBadge/toggle',
                data
            )
            .pipe(
                map((response: any) => {
                    return response
                })
            )
    }

    toggleIsCertificated(profileId, flag) {
        const data = {
            userId: profileId,
            flag
        }
        return this.httpClient
            .patch(
                this.globalsService.apiUrl + '/profile/isCertificated/toggle',
                data
            )
            .pipe(
                map((response: any) => {
                    return response
                })
            )
    }

    getPreferences() {
        return this.httpClient
            .get(
                this.globalsService.apiUrl +
                    '/profile/preferences/' +
                    this.userService.data.id
            )
            .pipe(
                map((response: any) => {
                    return response
                })
            )
    }

    savePreferences(preferences) {
        const data = {
            userId: this.userService.data.id,
            preferences
        }
        return this.httpClient
            .patch(this.globalsService.apiUrl + '/profile/preferences', data)
            .pipe(
                map((response: any) => {
                    return response
                })
            )
    }

    setRootChannel(channelId) {
        const data = {
            userId: this.userService.data.id,
            channelId
        }
        return this.httpClient
            .patch(this.globalsService.apiUrl + '/profile/rootChannel', data)
            .pipe(
                map((response: any) => {
                    return response
                })
            )
    }

    updateRadius() {
        const data = {
            userId: this.userService.data.id,
            radius: this.userService.data.search_local_radius
        }
        console.log(JSON.stringify(data))
        return this.httpClient
            .patch(this.globalsService.apiUrl + '/user/radius', data)
            .pipe(
                map((response: any) => {
                    return response
                })
            )
    }

    updateCitiesSearch(cities) {
        const data = {
            userId: this.userService.data.id,
            cities
        }
        console.log(JSON.stringify(data))
        return this.httpClient
            .post(this.globalsService.apiUrl + '/user/search/cities', data)
            .pipe(
                map((response: any) => {
                    return response
                })
            )
    }

    subscribeStatus(otherUserId) {
        return this.httpClient
            .get(
                this.globalsService.apiUrl +
                    `/network/subscribe/profile/status/${this.userService.data.id}/${otherUserId}`
            )
            .pipe(
                map((response: any) => {
                    return response
                })
            )
    }

    subscribe(profileId) {
        const data = {
            userId: this.userService.data.id,
            networkId: profileId,
            isPro: false
        }
        return this.httpClient
            .put(this.globalsService.apiUrl + '/network/subscribe', data)
            .pipe(
                map((response: any) => {
                    return response
                })
            )
    }

    askSubscribe(profileId) {
        const data = {
            userId: this.userService.data.id,
            networkId: profileId,
            isPro: false
        }
        return this.httpClient
            .put(this.globalsService.apiUrl + '/network/subscribe/ask', data)
            .pipe(
                map((response: any) => {
                    return response
                })
            )
    }

    unsubscribe(profileId) {
        const data = {
            userId: this.userService.data.id,
            networkId: profileId,
            isPro: false
        }
        return this.httpClient
            .put(this.globalsService.apiUrl + '/network/unsubscribe', data)
            .pipe(
                map((response: any) => {
                    return response
                })
            )
    }

    saveSearch(tagsIds) {
        const data = {
            userId: this.userService.data.id,
            searchParams: this.userService.searchParams,
            searchRadiusId: this.userService.data.search_radius_id,
            cities: null, // this.userService.data.citiesSearchJson,
            tagsIds: null
        }
        console.log(JSON.stringify(data))
        return this.httpClient
            .put(this.globalsService.apiUrl + '/user/search/save', data)
            .pipe(
                map((response: any) => {
                    return response
                })
            )
    }

    saveMission(missionId) {
        return this.httpClient
            .put(
                this.globalsService.apiUrl +
                    `/user/mission/save/${this.userService.data.id}/${missionId}`,
                null
            )
            .pipe(
                map((response: any) => {
                    return response
                })
            )
    }

    getFollowers(userId) {
        return this.httpClient
            .get(this.globalsService.apiUrl + `/user/${userId}/followers`)
            .pipe(
                map((response: any) => {
                    return response.rows
                })
            )
    }

    getSubscriptions(userId) {
        return this.httpClient
            .get(this.globalsService.apiUrl + `/user/${userId}/subscriptions`)
            .pipe(
                map((response: any) => {
                    return response.rows
                })
            )
    }

    getMissionsWon(userId) {
        return this.httpClient
            .get(this.globalsService.apiUrl + `/missions/won/${userId}`)
            .pipe(
                map((response: any) => {
                    return response.rows
                })
            )
    }

    getMissionsWonNested(userId) {
        return this.httpClient
            .get(this.globalsService.apiUrl + `/missions/won/nested/${userId}`)
            .pipe(
                map((response: any) => {
                    return this.globalsService.renameEmptyColumn(response.rows)
                })
            )
    }

    // lastProfiles() {
    //     return this.httpClient.get(this.globalsService.apiUrl + '/profiles/last', )
    //         .map((response: any) => {
    //             return response;
    //         });
    // }
}
