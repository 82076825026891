import { map } from 'rxjs/operators'
import { Injectable } from '@angular/core'
import { GlobalsService } from './globals.service'
import { UserService } from './user.service'
import { HttpClient } from '@angular/common/http'

@Injectable({
    providedIn: 'root'
})
export class ChannelsService {
    constructor(
        public httpClient: HttpClient,
        private globalsService: GlobalsService,
        private userService: UserService
    ) {}

    endPoint: string = null

    public setEndPoint(endPoint: string) {
        this.endPoint = endPoint
    }

    new(channel) {
        return this.httpClient.post(this.globalsService.apiUrl + '/channels/new', channel).pipe(
            map((response: any) => {
                const rows = this.globalsService.renameEmptyColumn(response.rows)
                response.rows = rows
                return response
            })
        )
    }

    delete(channelId) {
        return this.httpClient.delete(this.globalsService.apiUrl + '/channels/' + channelId).pipe(
            map((response: any) => {
                return response
            })
        )
    }

    byId(id) {
        return this.httpClient.get(this.globalsService.apiUrl + '/channels/' + id).pipe(
            map((response: any) => {
                return response
            })
        )
    }

    allWithKeywords(pipes, keywords, showAll, hubId, limit) {
        const data = {
            userId: this.userService.data.id,
            pipes,
            keywords,
            showAll,
            hubId,
            limit
        }
        return this.httpClient.post(this.globalsService.apiUrl + '/channels/all', data).pipe(
            map((response: any) => {
                return response.rows
            })
        )
    }

    allWithKeywordsNested(pipes, keywords, showAll, hubId, limit) {
        const data = {
            userId: this.userService.data.id,
            pipes,
            keywords,
            showAll,
            hubId,
            limit
        }
        return this.httpClient.post(this.globalsService.apiUrl + '/channels/all/nested', data).pipe(
            map((response: any) => {
                return this.globalsService.renameEmptyColumn(response.rows)
            })
        )
    }

    allForUser() {
        return this.httpClient
            .get(this.globalsService.apiUrl + '/channels/all/' + this.userService.data.id)
            .pipe(
                map((response: any) => {
                    return response.rows
                })
            )
    }

    allForUserNested() {
        return this.httpClient
            .get(this.globalsService.apiUrl + '/channels/all/nested/' + this.userService.data.id)
            .pipe(
                map((response: any) => {
                    return this.globalsService.renameEmptyColumn(response.rows)
                })
            )
    }

    getPublics(limit) {
        return this.httpClient
            .get(
                this.globalsService.apiUrl +
                    `/channels/publics/${limit}/${this.userService.data.id}`
            )
            .pipe(
                map((response: any) => {
                    return response.rows
                })
            )
    }

    recents(limit, all?) {
        const data = {
            userId: this.userService.data.id,
            limit,
            all
        }
        return this.httpClient.post(this.globalsService.apiUrl + '/channels/recents', data).pipe(
            map((response: any) => {
                return response.rows
            })
        )
    }

    getNews() {
        // Wordpress
        return this.httpClient.get(this.endPoint + '/posts').pipe(
            map((response: any) => {
                return response
            })
        )
    }

    getFeed(channelId: number, feed: string, limit: number) {
        return this.httpClient
            .get(this.globalsService.apiUrl + `/channels/${feed}/${channelId}/${limit}`)
            .pipe(
                map((response: any) => {
                    return response.rows
                })
            )
    }

    getMissions(data) {
        return this.httpClient.post(this.endPoint + '/missioon/missions', data).pipe(
            map((response: any) => {
                return response.rows
            })
        )
    }

    getTalents(data) {
        return this.httpClient.post(this.endPoint + '/missioon/talents', data).pipe(
            map((response: any) => {
                return response.rows
            })
        )
    }

    update(channel) {
        return this.httpClient.patch(this.globalsService.apiUrl + '/channel', channel).pipe(
            map((response: any) => {
                return response
            })
        )
    }

    createDefaultTheme(channelId) {
        const data = {
            theme: {
                channel_id: channelId,
                logo: null,
                primary: '#ff6600',
                foreground: '#FFFFFF',
                background: '#e0e0e0',
                light: '#FFFFFF',
                calm: '#CCCCCC',
                dark: '#050102',
                energized: null,
                danger: '#db5153'
            }
        }
        return this.httpClient.put(this.globalsService.apiUrl + '/channel/theme', data).pipe(
            map((response: any) => {
                return response
            })
        )
    }

    updateTheme(channel) {
        return this.httpClient.patch(this.globalsService.apiUrl + '/channel/theme', channel).pipe(
            map((response: any) => {
                return response
            })
        )
    }

    swipe(channelId, choice) {
        const data = {
            userId: this.userService.data.id,
            channelId,
            choice
        }
        return this.httpClient.put(this.globalsService.apiUrl + '/channel/swipe', data).pipe(
            map((response: any) => {
                return response
            })
        )
    }
}
