import { Component } from '@angular/core'
import { ModalController, NavController, NavParams } from '@ionic/angular'
import { GlobalsService } from 'src/app/services/globals.service'
import { RatingsService } from 'src/app/services/ratings.service'
import { UserService } from 'src/app/services/user.service'
import { SessionService } from 'src/app/services/session.service'

@Component({
    selector: 'app-mission-end',
    templateUrl: './mission-end.page.html',
    styleUrls: ['./mission-end.page.scss']
})
export class MissionEndPage {
    userRating = 0
    punctualityRating = 0
    appearanceRating = 0
    behaviorRating = 0
    skillsRating = 0
    missionId = 0
    message: string = null
    imgProfilesFolder: string = null
    // matchedCandidates: any = []
    profile: any = {}
    missionData: any = {}

    constructor(
        public navCtrl: NavController,
        public navParams: NavParams,
        private ratingsSrv: RatingsService,
        private globalsSrv: GlobalsService,
        private userSrv: UserService,
        public modalCtrl: ModalController,
        private sessionSrv: SessionService
    ) {
        this.imgProfilesFolder = this.globalsSrv.imgProfilesFolder
        // this.matchedCandidates = this.navParams.get('matchedCandidates')
        this.missionData = this.sessionSrv.data.missionData
        this.profile = this.sessionSrv.data.profile
        // this.missionId = this.navParams.get('missionId')
        this.missionId = this.missionData.M.id
    }

    onClose() {
        this.modalCtrl.dismiss()
    }

    onRatingChange(event) {
        this.userRating = Math.round(
            (this.punctualityRating +
                this.appearanceRating +
                this.behaviorRating +
                this.skillsRating) /
                4
        )
    }

    onCloseMission() {
        let otherUser = null
        this.profile.id == this.userSrv.data.id
            ? (otherUser = this.missionData.CALC.user_id_from)
            : (otherUser = this.missionData.CALC.user_id_to)
        this.ratingsSrv
            .rateUser(
                this.missionId,
                this.userSrv.data.id,
                this.profile.id,
                this.userRating,
                this.punctualityRating,
                this.appearanceRating,
                this.behaviorRating,
                this.skillsRating,
                this.message
            )
            .subscribe(res => {
                this.modalCtrl.dismiss({ isClosed: true })
            })
    }
}
