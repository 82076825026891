import { Component, OnInit } from '@angular/core'
import { NgForm } from '@angular/forms'
import { Plugins } from '@capacitor/core'
import { AlertController, NavController } from '@ionic/angular'
import { flatMap } from 'rxjs/operators'
import { Preferences } from '../models/preferences'
import { AuthSocialsService } from '../services/auth-socials.service'
import { AuthService } from '../services/auth.service'
import { EventsService } from '../services/events.service'
import { ProfilesService } from '../services/profiles.service'
import { PushService } from '../services/push.service'
import { PwaService } from '../services/pwa.service'
import { UserService } from '../services/user.service'

const { Storage } = Plugins

@Component({
    selector: 'app-login',
    templateUrl: './login.page.html',
    styleUrls: ['./login.page.scss']
})
export class LoginPage implements OnInit {
    data = null
    photoSocial: string = null
    jwt: string = undefined

    constructor(
        private navCtrl: NavController,
        private userService: UserService,
        private profilesService: ProfilesService,
        private pushService: PushService,
        public eventsSrv: EventsService,
        private alertCtrl: AlertController,
        private auth: AuthService,
        private authSocialsSrv: AuthSocialsService,
        public pwaSrv: PwaService
    ) {}

    ngOnInit() {
        this.data = this.userService.data
    }

    onLogin(form: NgForm, network: string) {
        if (form) {
            this.data.email = form.value.email
            this.data.password = form.value.password
        }

        const obs = this.auth
            .login(this.data.email, this.data.password)
            .pipe(
                flatMap(async data => {
                    if (data.success) {
                        this.jwt = undefined
                        if (data.jwt) {
                            this.jwt = data.jwt
                        }
                        this.userService.isConnected = true
                        this.userService.setData(data.body)
                        const resultRadius = await Storage.get({
                            key: 'ro_radius'
                        })
                        const radius = JSON.parse(resultRadius.value)
                        radius
                            ? (this.userService.data.radius = radius)
                            : (this.userService.data.radius = 1000)

                        this.getPreferences()

                        this.userService.jwt = this.jwt

                        await Storage.set({
                            key: 'jwt',
                            value: this.jwt
                        })

                        return this.profilesService.getAllTagsFromUser()
                    } else {
                        const alert = await this.alertCtrl.create({
                            header: `Connexion impossible`,
                            message: `Les codes saisis n'ont pas été reconnus.`,
                            buttons: [
                                {
                                    text: 'Ok'
                                }
                            ]
                        })
                        await alert.present()
                        obs.unsubscribe()
                    }
                }),
                flatMap((data: any) => {
                    this.userService.setTags(data.rows)
                    return this.pushService.updateToken(
                        this.userService.data.id,
                        this.userService.fcmToken
                    )
                })
            )
            .subscribe(
                data => {
                    if (
                        this.userService.isConnected == true &&
                        this.userService.data.confirmed == 0
                    ) {
                        this.navCtrl.navigateRoot(['/activation'])
                    } else if (
                        this.userService.isConnected == true &&
                        this.userService.data.confirmed == 1
                    ) {
                        this.profilesService
                            .updateLastConnection(this.userService.data.id)
                            .subscribe(() => {
                                // if (this.userService.data.latitude != null && this.userService.data.longitude != null) {
                                this.eventsSrv.publish('companies:update', {})
                                this.eventsSrv.publish('user:login', {})
                                this.navCtrl.navigateRoot(['/tabs/missioon'])
                                // } else {
                                //     this.app.getRootNavs()[0].setRoot('UserAddressPage')
                                // }
                            })
                    } else {
                        // this.navCtrl.push('LoginPage');
                    }
                    this.eventsSrv.publish('user:created', {})
                },
                error => {
                    console.error(JSON.stringify(error))
                }
            )
    }

    onPassword() {
        this.navCtrl.navigateRoot('password')
    }

    onRegister() {
        this.navCtrl.navigateRoot('register')
    }

    onFacebook() {
        this.authSocialsSrv.loginFacebook()
    }

    onGoogle() {
        this.authSocialsSrv.loginGoogle()
    }

    onLinkedIn() {
        this.authSocialsSrv.loginLinkedIn()
    }

    private getPreferences() {
        this.profilesService.getPreferences().subscribe(
            (prefs: Preferences) => {
                this.userService.prefs = prefs
                this.userService.searchOptions.radius = Number(
                    prefs.search_radius
                )
                this.userService.searchOptions.showAll = Boolean(prefs.showAll)
                this.userService.searchOptions.remember = Boolean(
                    prefs.search_remember
                )
            },
            err => {
                console.error(JSON.stringify(err))
            }
        )
    }

    installPwa() {
        this.pwaSrv.promptEvent.prompt()
    }
}
