import { Component } from '@angular/core'
import { NavController, ModalController } from '@ionic/angular'
import { SessionService } from '../services/session.service'

@Component({
    selector: 'app-show-on-card',
    templateUrl: './show-on-card.page.html',
    styleUrls: ['./show-on-card.page.scss']
})
export class ShowOnCardPage {
    latitude = 0
    longitude = 0

    constructor(
        public navCtrl: NavController,
        private modalCtrl: ModalController,
        private sessionSrv: SessionService
    ) {
        this.latitude = this.sessionSrv.currentLatitude
        this.longitude = this.sessionSrv.currentLongitude
    }

    onClose() {
        this.modalCtrl.dismiss()
    }
}
