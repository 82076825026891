import { HttpClient, HttpHeaders } from '@angular/common/http'
import { Injectable } from '@angular/core'

declare let cordova

@Injectable({
    providedIn: 'root'
})
export class LinkedinService {
    constructor(private httpClient: HttpClient) {}

    linkLogin() {
        this.linkedInLogin().then(
            success => {
                const headers = new HttpHeaders({
                    'Content-Type': 'application/x-www-form-urlencoded'
                })
                // var data: any
                const body =
                    'grant_type=authorization_code&code=' +
                    success.code +
                    '&redirect_uri=<REDIRECT_URI>&client_id=<CLIENT_ID>&client_secret=<CLIENT_SECRET>'
                this.httpClient
                    .post(
                        'https://www.linkedin.com/oauth/v2/accessToken',
                        body,
                        {
                            headers
                        }
                    )
                    .toPromise()
                    .then(
                        result => {
                            if (result['access_token'] !== undefined) {
                                this.getLinkedInUserDetails(
                                    result['access_token']
                                )
                                    .then(() => {
                                        console.log(
                                            'response is the JSON containing the user details from Linkedin.'
                                        )
                                    })
                                    .then(() => {})
                            } else {
                            }
                        },
                        err => {
                            console.log('Failed')
                        }
                    )
            },
            error => {
                console.log('error : ' + error)
            }
        )
    }

    linkedInLogin(): Promise<any> {
        return new Promise(function (resolve, reject) {
            const uri =
                'https://www.linkedin.com/oauth/v2/authorization?response_type=code&client_id=77wmbuah9ukeak&' +
                'redirect_uri=http://localhost&state=<STATE>&scope=r_basicprofile%20r_emailaddress'
            const browserRef = cordova.InAppBrowser.open(uri)
            browserRef.addEventListener('loadstart', event => {
                if (event.url.indexOf('http://localhost') === 0) {
                    browserRef.removeEventListener('exit', event => {})
                    browserRef.close()
                    const parsedResponse = {}
                    const code = event.url.split('=')[1].split('&')[0]
                    const state = event.url.split('=')[2]
                    if (code !== undefined && state !== null) {
                        parsedResponse['code'] = code
                        resolve(parsedResponse)
                    } else {
                        reject('Problem authenticating with LinkedIn')
                    }
                }
            })
            browserRef.addEventListener('exit', function (event) {
                reject('The LinkedIn sign in flow was canceled')
            })
        })
    }

    getLinkedInUserDetails(token: string) {
        const headers = new HttpHeaders({
            'Content-Type': 'application/x-www-form-urlencoded',
            Authorization: 'Bearer ' + token
        })
        return this.httpClient
            .get(
                'https://api.linkedin.com/v1/people/~:(id,first-name,last-name,headline,location,industry,public-profile-url,picture-urls::(original),picture-url,positions,email-address)?format=json',
                { headers }
            )
            .toPromise()
            .then(
                res => res,
                err => err
            )
    }
}
