import { Injectable } from '@angular/core'
import { ActivityParams } from '../models/activity-params'
import { Preferences } from '../models/preferences'

@Injectable({
    providedIn: 'root'
})
export class UserService {
    data = {
        id: '',
        hubId: '',
        rootChannelId: null,
        rootChannelName: null,
        prefixId: '',
        currentProfile: 0,
        is_private: 0,
        is_provider: 0,
        firstname: '',
        lastname: '',
        job: null,
        job_from: null,
        price_amount: 0,
        price_periodId: 1,
        email: '',
        address: '',
        zip: '',
        city: '',
        dob: null,
        latitude: '',
        longitude: '',
        radius: 0,
        confirmed: 0,
        photo_url: '',
        photo_social: '',
        network: '',
        website: '',
        date: '',
        phone: '',
        cellphone: '',
        languages: null,
        languagesArr: null,
        contractsArr: null,
        newsOnly: 1,
        showRating: 1,
        showMissionsBadge: 1,
        showCandidaturesBadge: 1,
        search_radius_id: 1,
        search_local_radius: 40,
        citiesSearch: null,
        citiesSearchJson: null,
        bioMini: null,
        isRoot: 0
    }

    pending = 0
    followed = 0

    prefs: Preferences = {
        search_radius: 50,
        search_remember: true,
        showAll: false
    }

    tags = []
    tagsFollowed = []
    searchParams = {
        typeId: 1, // 1 = Missions, 2 = Prestataires (profils)
        pipes: '',
        keywords: '',
        tags: []
    }

    activityParams: ActivityParams = {
        missionsIncoming: true,
        missionsInProgress: true,
        missionsWon: false,
        missionsCompleted: false,
        candidatures: false
    }

    score = {
        amount: 100
    }

    fcmToken: string
    jwt: string
    isConnected = false
    searchZoneId = 1
    zones = []

    searchOptions = {
        keywords: '',
        pipes: '',
        latitude: null,
        longitude: null,
        categoryId: 1,
        hubId: 4, // 4 = Événementiel
        ringId: 0,
        targetId: 1,
        radius: 50,
        showAll: false,
        remember: false
    }

    // constructor(private http: Http, private globalsService: GlobalsService, private auth: AuthProvider) {}
    constructor() {}

    setToken(token) {
        this.fcmToken = token
    }

    setJwt(jwt) {
        this.jwt = jwt
    }

    getData() {
        return this.data
    }

    setData(object: any) {
        let userId = null
        if (object.user_id) {
            userId = object.user_id
        } else {
            userId = object.id
        }

        let languagesArr = null
        if (object.languages !== null && object.languages !== undefined) {
            languagesArr = object.languages.split(',')
        }
        let contractsArr = null
        if (object.contracts !== null && object.contracts !== undefined) {
            contractsArr = object.contracts.split(',')
        }
        let csj = null
        if (object.cities_search_json) {
            csj = JSON.parse(object.cities_search_json)
        }
        this.data = {
            id: userId,
            hubId: object.hubId,
            rootChannelId: object.root_channel,
            rootChannelName: object.channelName,
            prefixId: object.prefixId,
            currentProfile: object.current_profile_id,
            is_private: object.is_private,
            is_provider: object.is_provider,
            firstname: object.firstname,
            lastname: object.lastname,
            job: object.job,
            job_from: object.job_from,
            price_amount: object.price_amount,
            price_periodId: object.price_period_id,
            email: object.email,
            address: object.address,
            zip: object.zip,
            city: object.city,
            dob: object.dob,
            longitude: object.longitude,
            latitude: object.latitude,
            radius: object.radius,
            confirmed: object.confirmed,
            photo_url: object.photo_url,
            photo_social: object.photo_social,
            network: object.network,
            website: object.website,
            date: object.date,
            phone: object.phone,
            cellphone: object.cellphone,
            languages: object.languages,
            languagesArr,
            contractsArr,
            newsOnly: object.newsOnly,
            showRating: object.show_rating,
            showMissionsBadge: object.show_missions_badge,
            showCandidaturesBadge: object.show_candidatures_badge,
            search_radius_id: object.search_radius_id,
            search_local_radius: object.search_local_radius,
            citiesSearch: object.cities_search,
            citiesSearchJson: csj,
            bioMini: object.bio_mini,
            isRoot: object.isRoot
        }
        if (this.data.longitude == '' || this.data.longitude == null) {
            this.score.amount = this.score.amount - 20
        }
        if (this.data.photo_url == '' || this.data.photo_url == null) {
            this.score.amount = this.score.amount - 20
        }
        if (this.data.cellphone == '' || this.data.cellphone == null) {
            this.score.amount = this.score.amount - 20
        }
    }

    getScore() {
        this.score.amount = 100
        // if (this.data.longitude == '' || this.data.longitude == null) {
        //     this.score.amount = this.score.amount - 50;
        // }
        if (this.data.photo_url == '' || this.data.photo_url == null) {
            this.score.amount = this.score.amount - 50
        }
        let isCellphoneOk = false
        if (this.data.cellphone != '' && this.data.cellphone != null) {
            isCellphoneOk = true
        }
        let isPhoneOk = false
        if (this.data.phone != '' && this.data.phone != null) {
            isPhoneOk = true
        }
        if (!isCellphoneOk && !isPhoneOk) {
            this.score.amount = this.score.amount - 20
        }
        return this.score
    }

    setTags(objects: any) {
        this.tags.length = 0
        this.tags.push.apply(this.tags, objects)
    }

    getTags() {
        return this.tags
    }

    setEmail(value: string) {
        this.data.email = value
    }

    setNewsOnly(value: number) {
        this.data.newsOnly = value
    }

    reset() {
        this.data = {
            id: '',
            hubId: '',
            rootChannelId: null,
            rootChannelName: null,
            prefixId: '',
            currentProfile: 0,
            is_private: 0,
            is_provider: 0,
            firstname: '',
            lastname: '',
            job: null,
            job_from: null,
            price_amount: 0,
            price_periodId: 1,
            email: '',
            address: '',
            zip: '',
            latitude: '',
            longitude: '',
            city: '',
            dob: null,
            radius: 0,
            confirmed: 0,
            photo_url: '',
            photo_social: '',
            network: '',
            website: null,
            date: '',
            phone: '',
            cellphone: '',
            languages: null,
            languagesArr: null,
            contractsArr: null,
            newsOnly: 1,
            showRating: 1,
            showMissionsBadge: 1,
            showCandidaturesBadge: 1,
            search_radius_id: 1,
            search_local_radius: 40,
            citiesSearch: null,
            citiesSearchJson: null,
            bioMini: null,
            isRoot: 0
        }
    }
}
