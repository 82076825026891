import { HttpClient } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { Badge } from '@ionic-native/badge/ngx'
import { map } from 'rxjs/operators'
import { GlobalsService } from './globals.service'
import { UserService } from './user.service'

@Injectable({
    providedIn: 'root'
})
export class NotificationsService {
    requestOptions = null

    constructor(
        private httpClient: HttpClient,
        private globalsService: GlobalsService,
        private userService: UserService,
        private badge: Badge
    ) {}

    all(userId) {
        let theId = userId
        if (userId == undefined) {
            theId = this.userService.data.id
        }
        return this.httpClient.get(this.globalsService.apiUrl + '/notifications/' + theId).pipe(
            map((response: any) => {
                return response.rows
            })
        )
    }

    allNested(userId) {
        let theId = userId
        if (userId == undefined) {
            theId = this.userService.data.id
        }
        return this.httpClient.get(this.globalsService.apiUrl + '/notifications/nested/' + theId).pipe(
            map((response: any) => {
                return this.globalsService.renameEmptyColumn(response.rows)
            })
        )
    }

    add(from, message, service, serviceId, senderId, roomName, toId) {
        const data = {
            from,
            message,
            service,
            serviceId,
            senderId,
            roomName,
            toId
        }
        return this.httpClient.put(this.globalsService.apiUrl + '/notification', data).pipe(
            map((response: any) => {
                return response
            })
        )
    }

    delete(id) {
        return this.httpClient.delete(this.globalsService.apiUrl + '/notification/' + id).pipe(
            map((response: any) => {
                this.badge.decrease(1)
                return response
            })
        )
    }

    deleteAllForMission(missionId) {
        return this.httpClient.delete(this.globalsService.apiUrl + '/notifications/mission/' + missionId).pipe(
            map((response: any) => {
                this.badge.decrease(1)
                return response
            })
        )
    }

    toggleRead(id) {
        const data = {
            id
        }
        return this.httpClient.patch(this.globalsService.apiUrl + '/notification/toggle', data).pipe(
            map((response: any) => {
                return response
            })
        )
    }
}
