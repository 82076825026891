// import { tap } from 'rxjs/operators'
import {
    HttpEvent,
    HttpHandler,
    HttpInterceptor,
    HttpRequest
    // HttpResponse,
    // HttpErrorResponse
} from '@angular/common/http'
import { Injectable } from '@angular/core'
import { Observable } from 'rxjs'
import { UserService } from '../services/user.service'

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
    constructor(private userSrv: UserService) {}

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        // console.warn(request.url + ' ===> ' + this.userSrv.jwt)
        if (request.url != 'https://fcm.googleapis.com/fcm/send') {
            const updatedRequest = request.clone({
                headers: request.headers.set('Authorization', 'Bearer ' + this.userSrv.jwt)
            })
            // console.log('log: AuthInterceptor -> constructor -> this.userSrv.jwt', this.userSrv.jwt)
            return next.handle(updatedRequest)
        } else {
            return next.handle(request)
        }
    }
}
