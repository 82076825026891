import { Component } from '@angular/core'
import { NgForm } from '@angular/forms'
import { Router } from '@angular/router'
import {
    AlertController,
    LoadingController,
    NavController
} from '@ionic/angular'
import { ProfilesService } from '../services/profiles.service'

@Component({
    selector: 'app-password',
    templateUrl: './password.page.html',
    styleUrls: ['./password.page.scss']
})
export class PasswordPage {
    constructor(
        private profilesSrv: ProfilesService,
        private loadingCtrl: LoadingController,
        private alertCtrl: AlertController,
        private navCtrl: NavController
    ) {}

    async onSubmit(form: NgForm) {
        const loadingCtrl = await this.loadingCtrl.create({
            message: `Vérification...`
        })
        await loadingCtrl.present()
        this.profilesSrv.sendPassword(form.value.email).subscribe(
            async res => {
                console.log('log: PasswordPage -> onSubmit -> res', res)
                if (res.success) {
                    loadingCtrl.dismiss()
                    const title = `Nouveau mot de passe`
                    let subTitle = null
                    res.network == 'mission'
                        ? (subTitle = `Un nouveau mot de passe vient d'être envoyé sur votre adresse e-mail.`)
                        : (subTitle =
                              `Veuillez vous connecter avec ` + res.network)
                    const alertSuccess = await this.alertCtrl.create({
                        header: title,
                        message: subTitle,
                        buttons: [
                            {
                                text: 'Ok',
                                handler: () => {
                                    this.navCtrl.pop()
                                }
                            }
                        ]
                    })
                    await alertSuccess.present()
                } else {
                    loadingCtrl.dismiss()
                    const alertError = await this.alertCtrl.create({
                        header: `Une erreur est survenue`,
                        message: res.reason,
                        buttons: [
                            {
                                text: 'Ok'
                            }
                        ]
                    })
                    await alertError.present()
                }
            },
            err => {
                console.error(err)
            }
        )
    }

    onClose() {
        this.navCtrl.navigateRoot('login')
    }
}
