import { Component, ElementRef, ViewChild } from '@angular/core'
import { Clipboard } from '@ionic-native/clipboard/ngx'
import {
    IonContent,
    IonList,
    ModalController,
    NavParams,
    ToastController
} from '@ionic/angular'
import { Socket } from 'ngx-socket-io'
import { ChatService } from 'src/app/services/chat.service'
import { GlobalsService } from 'src/app/services/globals.service'
import { PushService } from 'src/app/services/push.service'
import { UserService } from 'src/app/services/user.service'

@Component({
    selector: 'app-chat',
    templateUrl: './chat.page.html',
    styleUrls: ['./chat.page.scss']
})
export class ChatPage {
    // @ViewChild('content') content;
    @ViewChild(IonContent, { static: true }) content: IonContent
    @ViewChild(IonList, { read: ElementRef, static: true }) chatList: ElementRef

    profile = null
    username = null
    contactName = null
    room: string = null
    messages: any = []
    data = {
        message: ''
    }
    isSendButtonDisabled = false
    private mutationObserver: MutationObserver
    //

    // @ViewChild('IonContent') content: IonContent
    paramData: any
    msgList: any
    userName: any
    user_input = ''
    User = 'Me'
    toUser = 'HealthBot'
    start_typing: any
    loader: boolean

    constructor(
        public navParams: NavParams,
        private chatSrv: ChatService,
        public userSrv: UserService,
        private pushSrv: PushService,
        private socket: Socket,
        public globalsSrv: GlobalsService,
        private toastCtrl: ToastController,
        private clipboard: Clipboard,
        private modalCtrl: ModalController
    ) {
        this.paramData = {
            title: 'Bubble',
            url: 'bubble',
            icon: 'chatboxes',
            type: 'free'
        }
        this.userName = 'params.name'
    }

    ionViewWillEnter() {
        this.profile = this.navParams.get('profile')
        this.username = `${this.userSrv.data.firstname} ${this.userSrv.data.lastname}`
        this.contactName = `${this.profile.firstname} ${this.profile.lastname}`
        // Création de la room
        const lowest: number = Math.min(
            Number(this.userSrv.data.id),
            Number(this.profile.id)
        )
        const highest: number = Math.max(
            Number(this.userSrv.data.id),
            Number(this.profile.id)
        )
        this.room = lowest.toString() + highest.toString()
        this.chatSrv
            .toggleAllMessages(this.userSrv.data.id, this.profile.id, 1)
            .subscribe()
        this.chatSrv.allMessages(this.room).subscribe(res => {
            this.messages = res
            this.scrollDown()
            this.socket.emit('room', { room: this.room, from: this.username })
            this.socket.on('message', recievedMessage => {
                this.messages.push({
                    room: recievedMessage.room,
                    senderId: recievedMessage.senderId,
                    photo_url: recievedMessage.photo_url,
                    from: recievedMessage.from,
                    text: recievedMessage.text
                })
            })
        })
    }

    ionViewDidLoad() {
        this.mutationObserver = new MutationObserver(mutations => {
            this.scrollDown()
        })

        this.mutationObserver.observe(this.chatList.nativeElement, {
            childList: true
        })
    }

    onAddMessage() {
        if (this.data.message != '') {
            this.isSendButtonDisabled = true
            this.scrollDown()
            this.chatSrv
                .addMessage(this.room, this.userSrv.data.id, this.data.message)
                .subscribe(() => {
                    let token = this.profile.fcm_token
                    if (token == undefined || token == null || token == '') {
                        token = this.profile.token
                    }
                    this.pushSrv
                        .sendToFCM(
                            this.username,
                            this.data.message,
                            token,
                            'Chat',
                            this.userSrv.data.id,
                            null,
                            this.room,
                            this.profile.id
                        )
                        .subscribe(
                            res => console.log(JSON.stringify(res)),
                            err => console.log(JSON.stringify(err))
                        )
                    this.messages.push({
                        room: this.room,
                        senderId: this.userSrv.data.id,
                        photo_url: this.userSrv.data.photo_url,
                        from: this.username,
                        text: this.data.message
                    })
                    this.socket.emit('new message', {
                        room: this.room,
                        senderId: this.userSrv.data.id,
                        photo_url: this.userSrv.data.photo_url,
                        from: this.username,
                        text: this.data.message
                    })
                    this.data.message = ''
                    this.isSendButtonDisabled = false
                })
        }
    }

    async copyToClipboard(text) {
        this.clipboard.copy(text)
        const toast = await this.toastCtrl.create({
            message: 'Message copié !',
            duration: 1000
        })
        toast.present()
    }

    onClose() {
        this.modalCtrl.dismiss()
    }

    scrollDown() {
        setTimeout(() => {
            this.content.scrollToBottom(50)
        }, 50)
    }
}
