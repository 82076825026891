import { Component, OnInit } from '@angular/core'
import { SessionService } from 'src/app/services/session.service'
import { CompaniesService } from 'src/app/services/companies.service'
import { UserService } from 'src/app/services/user.service'
import { AlertController, ModalController } from '@ionic/angular'
import { CertificationsService } from 'src/app/services/certifications.service'
import { GlobalsService } from 'src/app/services/globals.service'

@Component({
    selector: 'app-certification-new',
    templateUrl: './certification-new.page.html',
    styleUrls: ['./certification-new.page.scss']
})
export class CertificationNewPage implements OnInit {
    constructor(
        public sessionSrv: SessionService,
        private companiesSrv: CompaniesService,
        private userSrv: UserService,
        private alertCtrl: AlertController,
        private certicationsSrv: CertificationsService,
        private modalCtrl: ModalController,
        public globalsSrv: GlobalsService
    ) {}

    // certifications: any = []
    certificationsCiesIds: any = []
    companies: any = []

    ngOnInit() {
        this.certificationsCiesIds = this.sessionSrv.currentCertifications.map(certification => {
            return certification.C.id
        })
        this.companiesSrv.allForUserNested(this.userSrv.data.id).subscribe(res => {
            this.companies = res.filter(company => {
                return !this.certificationsCiesIds.includes(company.C.id)
            })
        })
    }

    onClose() {
        this.modalCtrl.dismiss()
    }

    async onCertificate(company) {
        const alert = await this.alertCtrl.create({
            header: 'Êtes-vous certain ?',
            message: `${company.C.name} va certifier ${this.sessionSrv.currentProfile.firstname} ${this.sessionSrv.currentProfile.lastname}.`,
            buttons: [
                {
                    text: 'Annuler',
                    role: 'cancel',
                    cssClass: 'secondary'
                },
                {
                    text: 'Oui',
                    handler: () => {
                        this.certicationsSrv
                            .add(
                                this.sessionSrv.currentProfile.id,
                                company.C.id,
                                this.userSrv.data.id
                            )
                            .subscribe(res => {
                                this.modalCtrl.dismiss(company)
                            })
                    }
                }
            ]
        })

        await alert.present()
    }
}
