import { AgmCoreModule } from '@agm/core'
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http'
import { NgModule } from '@angular/core'
import { BrowserModule } from '@angular/platform-browser'
import { BrowserAnimationsModule } from '@angular/platform-browser/animations'
import { RouteReuseStrategy } from '@angular/router'
import { ServiceWorkerModule } from '@angular/service-worker'
import { Badge } from '@ionic-native/badge/ngx'
import { Clipboard } from '@ionic-native/clipboard/ngx'
import { Dialogs } from '@ionic-native/dialogs/ngx'
import { EmailComposer } from '@ionic-native/email-composer/ngx'
import { Facebook } from '@ionic-native/facebook/ngx'
import { FileChooser } from '@ionic-native/file-chooser/ngx'
import { FilePath } from '@ionic-native/file-path/ngx'
import { IOSFilePicker } from '@ionic-native/file-picker/ngx'
import { FileTransfer } from '@ionic-native/file-transfer/ngx'
import { File } from '@ionic-native/file/ngx'
// import { Firebase } from '@ionic-native/firebase/ngx'
// import { GooglePlus } from '@ionic-native/google-plus/ngx'
import { HTTP } from '@ionic-native/http/ngx'
import { NativeGeocoder } from '@ionic-native/native-geocoder/ngx'
import { SocialSharing } from '@ionic-native/social-sharing/ngx'
import { YoutubeVideoPlayer } from '@ionic-native/youtube-video-player/ngx'
import { IonicModule, IonicRouteStrategy } from '@ionic/angular'
import { SocketIoConfig, SocketIoModule } from 'ngx-socket-io'
import { environment } from '../environments/environment'
import '../rxjs-operators'
import { ActivityOptionsPageModule } from './activity/activity-options/activity-options.module'
import { AppRoutingModule } from './app-routing.module'
import { AppComponent } from './app.component'
import { CertificationNewPageModule } from './certifications/certification-new/certification-new.module'
import { ChooseDevicePhotosPageModule } from './choose-device-photos/choose-device-photos.module'
import { AuthInterceptor } from './interceptors/auth-interceptor'
import { LoginPageModule } from './login/login.module'
import { ChatPageModule } from './messages/chat/chat.module'
import { MissionEndPageModule } from './missions/mission-end/mission-end.module'
import { PasswordPageModule } from './password/password.module'
import { CommaSeperatedToArrayPipe } from './pipes/comma-seperated-to-array.pipe'
import { RegisterPageModule } from './register/register.module'
import { RingInvitePageModule } from './rings/ring-invite/ring-invite.module'
import { ShowOnMapPageModule } from './show-on-card/show-on-card.module'

const config: SocketIoConfig = {
    url: 'http://chat.missioon.com',
    options: {}
}

@NgModule({
    declarations: [AppComponent, CommaSeperatedToArrayPipe],
    entryComponents: [],
    imports: [
        BrowserModule,
        BrowserAnimationsModule,
        IonicModule.forRoot({
            backButtonText: ''
        }),
        AgmCoreModule.forRoot({
            apiKey: 'AIzaSyC38wX2Vt94x38gcyzDVEXHlQlmCa-gb3o'
        }),
        SocketIoModule.forRoot(config),
        AppRoutingModule,
        HttpClientModule,
        RegisterPageModule,
        LoginPageModule,
        PasswordPageModule,
        ActivityOptionsPageModule,
        ChatPageModule,
        MissionEndPageModule,
        ShowOnMapPageModule,
        RingInvitePageModule,
        CertificationNewPageModule,
        ChooseDevicePhotosPageModule,
        ServiceWorkerModule.register('ngsw-worker.js', {
            enabled: environment.production
        })
    ],
    providers: [
        Facebook,
        // GooglePlus,
        // LinkedIn,
        Badge,
        // Firebase,
        SocialSharing,
        EmailComposer,
        Dialogs,
        Clipboard,
        FileTransfer,
        NativeGeocoder,
        File,
        YoutubeVideoPlayer,
        FilePath,
        IOSFilePicker,
        FileChooser,
        HTTP,
        { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: AuthInterceptor,
            multi: true
        }
    ],
    bootstrap: [AppComponent]
})
export class AppModule {}
