import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'
import { FormsModule } from '@angular/forms'
import { Routes, RouterModule } from '@angular/router'

import { IonicModule } from '@ionic/angular'

import { MissionEndPage } from './mission-end.page'
import { IonicRatingModule } from 'ionic4-rating'

const routes: Routes = [
    {
        path: '',
        component: MissionEndPage
    }
]

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        IonicModule,
        IonicRatingModule,
        RouterModule.forChild(routes)
    ],
    declarations: [MissionEndPage]
})
export class MissionEndPageModule {}
