import { Pipe, PipeTransform } from '@angular/core'

@Pipe({
    name: 'commaSeperatedToArray'
})
export class CommaSeperatedToArrayPipe implements PipeTransform {
    transform(value: string, ...args) {
        if (value) {
            return value.split(',')
        }
    }
}
