import { Injectable } from '@angular/core'
import { Plugins } from '@capacitor/core'
import { Facebook, FacebookLoginResponse } from '@ionic-native/facebook/ngx'
// import { GooglePlus } from '@ionic-native/google-plus/ngx'
import { NavController } from '@ionic/angular'
import { Preferences } from '../models/preferences'
import { AuthService } from './auth.service'
import { EventsService } from './events.service'
import { LinkedinService } from './linkedin.service'
import { ProfilesService } from './profiles.service'
import { PushService } from './push.service'
import { UserService } from './user.service'
// import { LinkedIn } from 'ng2-cordova-oauth/core'
// import { OauthCordova } from 'ng2-cordova-oauth/platform/cordova'

const { Storage } = Plugins

@Injectable({
    providedIn: 'root'
})
export class AuthSocialsService {
    jwt: string = undefined
    photoSocial: string = null
    userData = {
        firstname: null,
        lastname: null,
        email: null,
        photo_url: null,
        network: null
    }

    // oauth: OauthCordova = new OauthCordova()
    // facebookProvider: LinkedIn = new LinkedIn({
    //     clientId: 'CLIENT_ID_HERE',
    //     appScope: ['email']
    // })

    constructor(
        private facebook: Facebook,
        // private googlePlus: GooglePlus,
        private auth: AuthService,
        private userService: UserService,
        private profilesService: ProfilesService,
        private pushService: PushService,
        private eventsSrv: EventsService,
        private navCtrl: NavController,
        private linkedInSrv: LinkedinService
    ) {}

    loginFacebook() {
        this.facebook
            .login(['public_profile', 'email'])
            .then((res: FacebookLoginResponse) => {
                this.facebook
                    .api('/me?fields=id,first_name,last_name,email', [])
                    .then(res => {
                        const fbEmail = res.email
                        this.userData.firstname = res.first_name
                        this.userData.lastname = res.last_name
                        this.userData.email = fbEmail
                        this.userData.photo_url =
                            'https://graph.facebook.com/' +
                            res.id +
                            '/picture?type=large'
                        this.socialLoginOrRegister(fbEmail, 'facebook')
                    })
                    .catch(e => console.error(JSON.stringify(e)))
            })
            .catch(e => console.error('Error logging into Facebook', e))
    }

    loginGoogle() {
        // this.googlePlus
        //     .login({})
        //     .then(res => {
        //         const ggEmail = res.email
        //         this.userData.firstname = res.givenName
        //         this.userData.lastname = res.familyName
        //         this.userData.email = ggEmail
        //         this.userData.photo_url = res.imageUrl
        //         this.socialLoginOrRegister(ggEmail, 'google')
        //     })
        //     .catch(err => console.error(JSON.stringify(err)))
    }

    loginLinkedIn() {
        this.linkedInSrv.linkedInLogin()
    }

    private socialLoginOrRegister(email: string, network: string) {
        this.photoSocial = this.userData.photo_url
        this.auth
            .getDataFromEmailAndNetwork(email, network)
            .subscribe(async data => {
                if (data.success) {
                    this.userService.setData(data.rows[0])
                    this.jwt = undefined
                    if (data.jwt) {
                        this.jwt = data.jwt
                    }
                    this.getPreferences()

                    await Storage.set({
                        key: 'jwt',
                        value: this.jwt
                    })

                    this.userData = data.rows[0]
                    this.userService.setData(data.rows[0])
                    this.userData.photo_url = this.photoSocial
                    this.userService.isConnected = true
                    Storage.set({
                        key: 'ro_radius',
                        value: data.radius
                    }).then(res => {
                        this.userService.data.radius = data.radius
                    })
                    this.userService.jwt = this.jwt
                    Storage.set({
                        // TODO: doublon avec celui qui précère ?
                        key: 'jwt',
                        value: data.jwt
                    })
                    this.profilesService
                        .updatePhotoSocialUrl(this.userData)
                        .subscribe()
                    this.profilesService
                        .getAllTagsFromUser()
                        .subscribe(data => {
                            this.userService.setTags(data.rows)
                            this.pushService
                                .updateToken(
                                    this.userService.data.id,
                                    this.userService.fcmToken
                                )
                                .subscribe(
                                    data => {
                                        if (
                                            this.userService.isConnected ==
                                                true &&
                                            this.userService.data.confirmed == 0
                                        ) {
                                            this.navCtrl.navigateRoot([
                                                '/activation'
                                            ])
                                        } else if (
                                            this.userService.isConnected ==
                                                true &&
                                            this.userService.data.confirmed == 1
                                        ) {
                                            this.profilesService
                                                .updateLastConnection(
                                                    this.userService.data.id
                                                )
                                                .subscribe(() => {
                                                    this.eventsSrv.publish(
                                                        'companies:update',
                                                        {}
                                                    )
                                                    this.eventsSrv.publish(
                                                        'user:login',
                                                        {}
                                                    )
                                                    this.navCtrl.navigateRoot([
                                                        '/tabs/missioon'
                                                    ])
                                                })
                                        }
                                        this.eventsSrv.publish(
                                            'user:created',
                                            {}
                                        )
                                    },
                                    error =>
                                        console.error(JSON.stringify(error))
                                )
                        })
                } else {
                    // Register if login fails
                    this.userData.network = network
                    this.profilesService.newAccount(this.userData).subscribe(
                        (data: any) => {
                            console.log(JSON.stringify(data))
                            if (data.success) {
                                this.userService.jwt = data.jwt
                                Storage.set({
                                    key: 'jwt',
                                    value: data.jwt
                                })
                                this.pushService
                                    .saveToken(
                                        data.body.id,
                                        this.userService.fcmToken
                                    )
                                    .subscribe(
                                        () => {
                                            this.userService.setData(data.body)
                                            this.eventsSrv.publish(
                                                'user:created',
                                                {}
                                            )
                                            this.navCtrl.navigateRoot([
                                                '/activation'
                                            ])
                                        },
                                        err => console.log(JSON.stringify(err))
                                    )
                            }
                        },
                        error => console.error(JSON.stringify(error))
                    )
                }
            })
    }

    private getPreferences() {
        this.profilesService.getPreferences().subscribe(
            (prefs: Preferences) => {
                this.userService.prefs = prefs
                this.userService.searchOptions.radius = Number(
                    prefs.search_radius
                )
                this.userService.searchOptions.showAll = Boolean(prefs.showAll)
                this.userService.searchOptions.remember = Boolean(
                    prefs.search_remember
                )
            },
            err => {
                console.error(JSON.stringify(err))
            }
        )
    }
}
