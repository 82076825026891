import { HttpClient } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { map } from 'rxjs/operators'
import { GlobalsService } from './globals.service'
import { UserService } from './user.service'

@Injectable({
    providedIn: 'root'
})
export class CompaniesService {
    requestOptions = null

    constructor(
        private httpClient: HttpClient,
        private globalsService: GlobalsService,
        private userService: UserService
    ) {}

    getTypes() {
        return this.httpClient
            .get(this.globalsService.apiUrl + '/companies/types')
            .pipe(
                map((response: any) => {
                    return response.rows
                })
            )
    }

    byId(id) {
        return this.httpClient
            .get(this.globalsService.apiUrl + '/company/' + id)
            .pipe(
                map((response: any) => {
                    return response.rows[0]
                })
            )
    }

    all() {
        return this.httpClient
            .get(this.globalsService.apiUrl + '/companies/all')
            .pipe(
                map((response: any) => {
                    return response.rows
                })
            )
    }

    allWithKeywords(pipes, keywords) {
        const data = {
            pipes,
            keywords,
            userId: this.userService.data.id
        }
        return this.httpClient
            .post(this.globalsService.apiUrl + '/companies/all', data)
            .pipe(
                map((response: any) => {
                    return response.rows
                })
            )
    }

    allWithKeywordsAround(
        pipes,
        keywords,
        latitude,
        longitude,
        km,
        showAll,
        hubId,
        limit
    ) {
        const data = {
            userId: this.userService.data.id,
            pipes,
            keywords,
            latitude,
            longitude,
            km,
            showAll,
            hubId,
            limit
        }
        return this.httpClient
            .post(this.globalsService.apiUrl + '/companies/all/near', data)
            .pipe(
                map((response: any) => {
                    return response.rows
                })
            )
    }

    recents(limit, all?) {
        const data = {
            userId: this.userService.data.id,
            limit,
            all
        }
        return this.httpClient
            .post(this.globalsService.apiUrl + '/companies/recents', data)
            .pipe(
                map((response: any) => {
                    return response.rows
                })
            )
    }

    allForUser(userId) {
        return this.httpClient
            .get(this.globalsService.apiUrl + '/companies/all/' + userId)
            .pipe(
                map((response: any) => {
                    return response.rows
                })
            )
    }

    allForUserNested(userId) {
        return this.httpClient
            .get(this.globalsService.apiUrl + '/companies/all/nested/' + userId)
            .pipe(
                map((response: any) => {
                    const rows = this.globalsService.renameEmptyColumn(
                        response.rows
                    )
                    const rowsUpdated = rows.map(company => {
                        company.CALC.photos != null
                            ? (company.CALC.arrayPhotos = company.CALC.photos.split(
                                  ','
                              ))
                            : (company.CALC.arrayPhotos = [])
                        return company
                    })
                    return rowsUpdated
                })
            )
    }

    add(newCompany) {
        return this.httpClient
            .post(this.globalsService.apiUrl + '/companies/add', newCompany)
            .pipe(
                map((response: any) => {
                    return response
                })
            )
    }

    addNested(newCompany) {
        return this.httpClient
            .post(
                this.globalsService.apiUrl + '/companies/add/nested',
                newCompany
            )
            .pipe(
                map((response: any) => {
                    return response
                })
            )
    }

    update(company) {
        return this.httpClient
            .patch(this.globalsService.apiUrl + '/company', company)
            .pipe(
                map((response: any) => {
                    return response
                })
            )
    }

    updatePhoto(data) {
        // en réalité c'est un DELETE
        return this.httpClient
            .patch(this.globalsService.apiUrl + '/company/photo', data)
            .pipe(
                map((response: any) => {
                    return response
                })
            )
    }

    delete(company) {
        return this.httpClient
            .delete(this.globalsService.apiUrl + '/companies/' + company.C.id)
            .pipe(
                map((response: any) => {
                    return response
                })
            )
    }

    swipe(proId, choice) {
        const data = {
            userId: this.userService.data.id,
            proId,
            choice
        }
        // console.log(JSON.stringify(data))
        return this.httpClient
            .put(this.globalsService.apiUrl + '/pro/swipe', data)
            .pipe(
                map((response: any) => {
                    return response
                })
            )
    }

    associateTagsWithCompany(insertId, arrayOfTags) {
        return this.httpClient
            .post(
                this.globalsService.apiUrl + '/tags/add/company/' + insertId,
                arrayOfTags
            )
            .pipe(
                map((response: any) => {
                    return response
                })
            )
    }

    removeAllTags(id) {
        return this.httpClient
            .delete(this.globalsService.apiUrl + '/company/tags/all/' + id)
            .pipe(
                map((response: any) => {
                    return response
                })
            )
    }

    addLogo(company_id, extension) {
        const data = {
            company_id,
            extension
        }
        return this.httpClient
            .post(this.globalsService.apiUrl + '/company/logo', data)
            .pipe(
                map((response: any) => {
                    return response
                })
            )
    }
}
