import { Component } from '@angular/core'
import { Plugins } from '@capacitor/core'
import { UserService } from 'src/app/services/user.service'

const { Storage } = Plugins

@Component({
    selector: 'app-activity-options',
    templateUrl: './activity-options.page.html',
    styleUrls: ['./activity-options.page.scss']
})
export class ActivityOptionsPage {
    constructor(public userSrv: UserService) {}

    ionViewWillLeave() {
        Storage.set({
            key: 'activityParams',
            value: JSON.stringify(this.userSrv.activityParams)
        })
    }
}
