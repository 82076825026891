import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'
import { FormsModule } from '@angular/forms'
import { Routes, RouterModule } from '@angular/router'

import { IonicModule } from '@ionic/angular'

import { ChatPage } from './chat.page'
import { MomentModule } from 'ngx-moment'

const routes: Routes = [
    {
        path: '',
        component: ChatPage
    }
]

@NgModule({
    imports: [CommonModule, FormsModule, IonicModule, MomentModule, RouterModule.forChild(routes)],
    declarations: [ChatPage]
})
export class ChatPageModule {}
