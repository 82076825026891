import { HttpClient } from '@angular/common/http'
import { Injectable } from '@angular/core'
import * as moment from 'moment'
import { map } from 'rxjs/operators'
import { Mission } from '../models/mission'
import { GlobalsService } from './globals.service'
import { UserService } from './user.service'

@Injectable({
    providedIn: 'root'
})
export class MissionsService {
    // public missions = []
    public currentMission: Mission
    public currentMissionPhoto
    // public currentMissionPhotoPath
    // public currentMissionPhotoWebPath

    constructor(
        private httpClient: HttpClient,
        private globalsService: GlobalsService,
        private userService: UserService
    ) {}

    public resetCurrentMission() {
        this.currentMission = {
            title: '',
            details: '',
            tags: [],
            service: undefined,
            search_zone_id: 1,
            cities_search_json: [],
            latitude: null,
            longitude: null,
            search_radius: this.userService.data.search_local_radius,
            datesOk: true,
            dateFrom: moment().add(1, 'days').format('YYYY-MM-DD'),
            dateTo: moment().add(1, 'days').format('YYYY-MM-DD'),
            ringId: 0,
            companyId: 0
        }
        this.currentMissionPhoto = null
    }

    byId(id) {
        return this.httpClient
            .get(this.globalsService.apiUrl + '/mission/' + id)
            .pipe(
                map((response: any) => {
                    return response.rows
                })
            )
    }

    byIdNested(id) {
        return this.httpClient
            .get(this.globalsService.apiUrl + '/mission/nested/' + id)
            .pipe(
                map((response: any) => {
                    return this.globalsService.renameEmptyColumn(response.rows)
                })
            )
    }

    byIdShort(id) {
        return this.httpClient
            .get(this.globalsService.apiUrl + '/mission/short/' + id)
            .pipe(
                map((response: any) => {
                    return this.globalsService.renameEmptyColumn(response.rows)
                })
            )
    }

    all() {
        return this.httpClient
            .get(this.globalsService.apiUrl + '/missions/all')
            .pipe(
                map((response: any) => {
                    return response.rows
                })
            )
    }

    filtered(
        pipes,
        keywords,
        tagsIds,
        tagsCount /*, latitude, longitude, km*/,
        userId /*, showAll, hubId*/
    ) {
        const data = {
            pipes,
            keywords,
            tagsIds,
            tagsCount,
            // latitude: latitude,
            // longitude: longitude,
            // km: km,
            userId
            // showAll: showAll,
            // hubId: hubId
        }
        return this.httpClient
            .post(this.globalsService.apiUrl + '/missions/filtered', data)
            .pipe(
                map((response: any) => {
                    return response.rows
                })
            )
    }

    filteredNested(
        pipes,
        keywords,
        tagsIds,
        tagsCount /*, latitude, longitude, km*/,
        userId /*, showAll, hubId*/,
        activitiesTagsIds,
        limit,
        nextItems
    ) {
        const data = {
            pipes,
            keywords,
            tagsIds,
            tagsCount,
            // latitude: latitude,
            // longitude: longitude,
            // km: km,
            userId,
            activitiesTagsIds,
            limit,
            nextItems
            // showAll: showAll,
            // hubId: hubId
        }
        return this.httpClient
            .post(
                this.globalsService.apiUrl + '/missions/filtered/nested',
                data
            )
            .pipe(
                map((response: any) => {
                    const obj = this.globalsService.renameEmptyColumn(
                        response.rows
                    )
                    obj.map(mission => {
                        mission.CALC.service = JSON.parse(mission.CALC.service)
                    })
                    return obj
                })
            )
    }

    // allNearNested(latitude, longitude, distance) {
    //     return this.httpClient
    //         .get(
    //             this.globalsService.apiUrl + `/missions/near/nested/${latitude}/${longitude}/${distance}`,
    //
    //         )
    //         .map((response: any) => {
    //             return this.globalsService.renameEmptyColumn(response.rows)
    //         })
    // }

    allNearNested(pipes, keywords, latitude, longitude, distance) {
        const data = {
            pipes,
            keywords,
            latitude,
            longitude,
            distance
        }
        console.log(JSON.stringify(data))
        return this.httpClient
            .post(this.globalsService.apiUrl + `/missions/near/nested`, data)
            .pipe(
                map((response: any) => {
                    return this.globalsService.renameEmptyColumn(response.rows)
                })
            )
    }

    // allNearCitiesNested(latitude, longitude, distance) {
    //     return this.httpClient
    //         .get(
    //             this.globalsService.apiUrl + `/missions/near/cities/nested/${latitude}/${longitude}/${distance}`,
    //
    //         )
    //         .map((response: any) => {
    //             return this.globalsService.renameEmptyColumn(response.rows)
    //         })
    // }

    allNearCitiesNested(pipes, keywords, latitude, longitude, distance) {
        const data = {
            pipes,
            keywords,
            latitude,
            longitude,
            distance
        }
        return this.httpClient
            .post(
                this.globalsService.apiUrl + `/missions/near/cities/nested`,
                data
            )
            .pipe(
                map((response: any) => {
                    return this.globalsService.renameEmptyColumn(response.rows)
                })
            )
    }

    recents(limit) {
        const data = {
            userId: this.userService.data.id,
            limit
        }
        return this.httpClient
            .post(this.globalsService.apiUrl + '/missions/recents', data)
            .pipe(
                map((response: any) => {
                    return response.rows
                })
            )
    }

    recentsNested(limit, nextItems) {
        const data = {
            userId: this.userService.data.id,
            limit,
            nextItems
        }
        return this.httpClient
            .post(this.globalsService.apiUrl + '/missions/recents/nested', data)
            .pipe(
                map((response: any) => {
                    // return response.rows
                    const obj = this.globalsService.renameEmptyColumn(
                        response.rows
                    )
                    obj.map(mission => {
                        mission.CALC.service = JSON.parse(mission.CALC.service)
                    })
                    return obj
                })
            )
    }

    allExceptMine(userId) {
        return this.httpClient
            .get(this.globalsService.apiUrl + '/missions/all/' + userId)
            .pipe(
                map((response: any) => {
                    return response.rows
                })
            )
    }

    allByTargetExceptMine(userId, target) {
        return this.httpClient
            .get(
                this.globalsService.apiUrl +
                    '/missions/all/' +
                    userId +
                    '/' +
                    target.id
            )
            .pipe(
                map((response: any) => {
                    return response.rows
                })
            )
    }

    // allByRingAndTargetExceptMine(userId, target, ring) {
    //     var targetId = 1;
    //     if (target == 'Personnel') {targetId = 1;}
    //     if (target == 'Vente') {targetId = 2;}
    //     if (target == 'Location') {targetId = 3;}
    //     missions = [];
    //     return this.httpClient.get(this.globalsService.apiUrl + '/missions/all/' + userId + '/' + targetId).then(function (res) {
    //         missions = res.data.rows;
    //         return missions;
    //     });
    // }

    allWithKeywords(pipes, keywords) {
        const data = {
            pipes,
            keywords
        }
        return this.httpClient
            .post(this.globalsService.apiUrl + '/missions/all', data)
            .pipe(
                map((response: any) => {
                    return response.rows
                })
            )
    }

    allWithKeywordsExceptMine(pipes, keywords, userId, target) {
        const data = {
            pipes,
            keywords,
            userId,
            targetId: target.id
        }
        return this.httpClient
            .post(this.globalsService.apiUrl + '/missions/all/exceptMine', data)
            .pipe(
                map((response: any) => {
                    return response.rows
                })
            )
    }

    allWithKeywordsAround(pipes, keywords, latitude, longitude, km) {
        const data = {
            pipes,
            keywords,
            latitude,
            longitude,
            km
        }
        return this.httpClient
            .post(this.globalsService.apiUrl + '/missions/all/near', data)
            .pipe(
                map((response: any) => {
                    return response.rows
                })
            )
    }

    allWithKeywordsAroundExceptMine(
        pipes,
        keywords,
        tagsIds,
        tagsCount,
        latitude,
        longitude,
        km,
        userId,
        showAll,
        hubId
    ) {
        const data = {
            pipes,
            keywords,
            tagsIds,
            tagsCount,
            latitude,
            longitude,
            km,
            userId,
            showAll,
            hubId
        }
        // console.log(JSON.stringify(data))
        return this.httpClient
            .post(
                this.globalsService.apiUrl + '/missions/all/near/exceptMine',
                data
            )
            .pipe(
                map((response: any) => {
                    return response.rows
                })
            )
    }

    allForUser(userId) {
        // this.missions = []
        return this.httpClient
            .get(this.globalsService.apiUrl + '/missions/' + userId)
            .pipe(
                map((response: any) => {
                    // this.missions = response.rows
                    return response.rows
                })
            )
    }

    allForUserShort(userId) {
        // this.missions = []
        return this.httpClient
            .get(this.globalsService.apiUrl + '/missions/short/' + userId)
            .pipe(
                map((response: any) => {
                    const obj = this.globalsService.renameEmptyColumn(
                        response.rows
                    )
                    obj.map(mission => {
                        mission.CALC.service = JSON.parse(mission.CALC.service)
                        mission.CALC.firebase_urls_json = JSON.parse(
                            mission.CALC.firebase_urls_json
                        )
                    })
                    return obj
                })
            )
    }

    allForUserCompany(userId, companyId) {
        return this.httpClient
            .get(
                this.globalsService.apiUrl +
                    '/missions/all/' +
                    userId +
                    '/' +
                    companyId
            )
            .pipe(
                map((response: any) => {
                    return response.rows
                })
            )
    }

    allForUserCompanyNested(userId, companyId) {
        return this.httpClient
            .get(
                this.globalsService.apiUrl +
                    '/missions/all/nested/' +
                    userId +
                    '/' +
                    companyId
            )
            .pipe(
                map((response: any) => {
                    return this.globalsService.renameEmptyColumn(response.rows)
                })
            )
    }

    add(newMission, tags) {
        const data = {
            newMission,
            tags
        }
        data.newMission.datesOk = data.newMission.datesOk ? 1 : 0
        data.newMission.hoursOk = data.newMission.hoursOk ? 1 : 0
        return this.httpClient
            .post(this.globalsService.apiUrl + '/missions/add', data)
            .pipe(
                map((response: any) => {
                    return response
                })
            )
    }

    addShort(mission) {
        // const data = {
        //     userId: this.userService.data.id,
        //     mission
        // }
        mission.M.datesOk = mission.M.datesOk ? 1 : 0
        if (mission.M.company_id == 0) {
            mission.M.company_id = null
        }
        // mission.M.dateFrom = '2019-10-10 08:00:00'
        // mission.M.dateTo = '2019-10-10 08:00:00'
        return this.httpClient
            .post(this.globalsService.apiUrl + '/missions/short/add', mission)
            .pipe(
                map((response: any) => {
                    return response
                })
            )
    }

    isAlreadyForwarded(missionId) {
        return this.httpClient
            .get(this.globalsService.apiUrl + '/mission/forwarded/' + missionId)
            .pipe(
                map((response: any) => {
                    return response.rows
                })
            )
    }

    addForward(
        initialId: number,
        forwardedId: number,
        ringId: number,
        recipientsArray: []
    ) {
        const data = {
            initialId,
            forwardedId,
            ringId,
            recipientsArray
        }
        console.log('log: data', data)
        return this.httpClient
            .post(this.globalsService.apiUrl + '/missions/forward', data)
            .pipe(
                map((response: any) => {
                    return response
                })
            )
    }

    joinCitiesToMission(missionId, cities) {
        const data = {
            missionId,
            cities
        }
        return this.httpClient
            .post(this.globalsService.apiUrl + '/mission/cities', data)
            .pipe(
                map((response: any) => {
                    return response
                })
            )
    }

    update(updatedMission, tags) {
        const data = {
            mission: updatedMission,
            tags
        }
        return this.httpClient
            .patch(this.globalsService.apiUrl + '/mission', data)
            .pipe(
                map((response: any) => {
                    return response
                })
            )
    }

    updateShort(updatedMission) {
        return this.httpClient
            .patch(
                this.globalsService.apiUrl + '/mission/short/update',
                updatedMission
            )
            .pipe(
                map((response: any) => {
                    return response
                })
            )
    }

    removePhoto(missionId, filename) {
        const data = {
            missionId,
            filename
        }
        return this.httpClient
            .patch(this.globalsService.apiUrl + '/mission/photo', data)
            .pipe(
                map((response: any) => {
                    return response
                })
            )
    }

    close(missionId) {
        const data = {
            // missionId: mission.id
            missionId
        }
        return this.httpClient
            .patch(this.globalsService.apiUrl + '/mission/close', data)
            .pipe(
                map((response: any) => {
                    return response
                })
            )
    }

    end(missionId) {
        const data = {
            missionId
        }
        return this.httpClient
            .patch(this.globalsService.apiUrl + '/mission/end', data)
            .pipe(
                map((response: any) => {
                    return response
                })
            )
    }

    delete(mission) {
        return this.httpClient
            .delete(this.globalsService.apiUrl + '/missions/' + mission.id)
            .pipe(
                map((response: any) => {
                    return response
                })
            )
    }

    updateMissionUser(missionId, userId) {
        const data = {
            missionId,
            userId
        }
        return this.httpClient
            .put(this.globalsService.apiUrl + '/mission/views/user', data)
            .pipe(
                map((response: any) => {
                    return response
                })
            )
    }

    updateViewsCount(missionId) {
        return this.httpClient
            .patch(
                this.globalsService.apiUrl + '/mission/views/' + missionId,
                null
            )
            .pipe(
                map((response: any) => {
                    return response
                })
            )
    }

    getViews(missionId) {
        return this.httpClient
            .get(this.globalsService.apiUrl + '/mission/views/' + missionId)
            .pipe(
                map((response: any) => {
                    return response
                })
            )
    }

    getUsers(missionId) {
        return this.httpClient
            .get(this.globalsService.apiUrl + '/mission/users/' + missionId)
            .pipe(
                map((response: any) => {
                    return response
                })
            )
    }

    getPostulants(id) {
        return this.httpClient
            .get(this.globalsService.apiUrl + '/mission/postulants/' + id)
            .pipe(
                map((response: any) => {
                    return response.rows
                })
            )
    }

    deletePostulant(id) {
        return this.httpClient
            .delete(this.globalsService.apiUrl + '/mission/postulant/' + id)
            .pipe(
                map((response: any) => {
                    return response
                })
            )
    }

    alreadyAsked(missionId, userId) {
        return this.httpClient
            .get(
                this.globalsService.apiUrl +
                    '/missions/alreadyAsked/' +
                    missionId +
                    '/' +
                    userId
            )
            .pipe(
                map((response: any) => {
                    return response.alreadyAsked
                })
            )
    }

    swipe(missionId, choice) {
        const data = {
            userId: this.userService.data.id,
            missionId,
            choice
        }
        return this.httpClient
            .put(this.globalsService.apiUrl + '/mission/swipe', data)
            .pipe(
                map((response: any) => {
                    return response
                })
            )
    }

    allWithSpecificTag(tagId) {
        return this.httpClient
            .get(
                this.globalsService.apiUrl +
                    '/missions/tag/' +
                    tagId +
                    '/' +
                    this.userService.data.id
            )
            .pipe(
                map((response: any) => {
                    return response.rows
                })
            )
    }

    linkWithGroup(missionId, groupId) {
        const data = {
            missionId,
            groupId
        }
        return this.httpClient
            .put(this.globalsService.apiUrl + '/mission/linkWithGroup', data)
            .pipe(
                map((response: any) => {
                    return response
                })
            )
    }

    allTagsFromMission(missionId) {
        return this.httpClient
            .get(this.globalsService.apiUrl + '/tags/mission/' + missionId)
            .pipe(
                map((response: any) => {
                    return response
                })
            )
    }

    // addNewTags(arrayOfTags) {
    //     return this.httpClient.post(this.globalsService.apiUrl + '/tags/add', arrayOfTags).then(function(res) {
    //         return res;
    //     });
    // }

    associateTagsWithMission(insertId, arrayOfTags) {
        return this.httpClient
            .post(
                this.globalsService.apiUrl + '/tags/add/mission/' + insertId,
                arrayOfTags
            )
            .pipe(
                map((response: any) => {
                    return response
                })
            )
    }

    removeAllTags(id) {
        return this.httpClient
            .delete(this.globalsService.apiUrl + '/mission/tags/all/' + id)
            .pipe(
                map((response: any) => {
                    return response
                })
            )
    }

    getCandidatureStatus(missionId) {
        return this.httpClient
            .get(
                this.globalsService.apiUrl +
                    '/mission/status/' +
                    missionId +
                    '/' +
                    this.userService.data.id
            )
            .pipe(
                map((response: any) => {
                    return response
                })
            )
    }

    getDocuments(missionId) {
        return this.httpClient
            .get(this.globalsService.apiUrl + '/mission/documents/' + missionId)
            .pipe(
                map((response: any) => {
                    return response.rows
                })
            )
    }

    getServices() {
        return this.httpClient
            .get(this.globalsService.apiUrl + '/missions/services/all')
            .pipe(
                map((response: any) => {
                    return response.rows
                })
            )
    }
}
