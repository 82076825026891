import { HttpClient } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { map } from 'rxjs/operators'
import { GlobalsService } from './globals.service'
import { UserService } from './user.service'

@Injectable({
    providedIn: 'root'
})
export class RingsService {
    requestOptions = null
    currentRing: any = {}

    constructor(
        private httpClient: HttpClient,
        private globalsService: GlobalsService,
        private userService: UserService
    ) {}

    // searchAllAvailable(ringId) {
    //     return this.httpClient.get(this.globalsService.apiUrl + '/search/rings/' + ringId + '/' + this.userService.data.id, )
    //         .pipe(map((response: any) => {
    //             return response.rows
    //         })
    // }

    byIdNested(ringId) {
        const userId = this.userService.data.id
        return this.httpClient
            .get(this.globalsService.apiUrl + `/rings/nested/${ringId}/${userId}`)
            .pipe(
                map((response: any) => {
                    return this.globalsService.renameEmptyColumn(response.rows)
                })
            )
    }

    searchAllAvailable(ringId, limit) {
        return this.httpClient.get(
            this.globalsService.apiUrl +
                '/search/rings/' +
                ringId +
                '/' +
                this.userService.data.id +
                '/' +
                limit
        )
    }

    allForUser(userId) {
        return this.httpClient.get(this.globalsService.apiUrl + '/rings/all/' + userId).pipe(
            map((response: any) => {
                return response.rows
            })
        )
    }

    allOwnedByUserNested(userId) {
        return this.httpClient
            .get(this.globalsService.apiUrl + '/rings/owned/nested/' + userId)
            .pipe(
                map((response: any) => {
                    // return response.rows
                    return this.globalsService.renameEmptyColumn(response.rows)
                })
            )
    }

    allMemberForUserNested(userId) {
        return this.httpClient
            .get(this.globalsService.apiUrl + '/rings/member/nested/' + userId)
            .pipe(
                map((response: any) => {
                    // return response.rows
                    return this.globalsService.renameEmptyColumn(response.rows)
                })
            )
    }

    recents(limit, all?) {
        const data = {
            userId: this.userService.data.id,
            limit,
            all
        }
        return this.httpClient.post(this.globalsService.apiUrl + '/rings/recents', data).pipe(
            map((response: any) => {
                return response.rows
            })
        )
    }

    allWithKeywords(pipes, keywords, showAll, hubId, limit) {
        const data = {
            userId: this.userService.data.id,
            pipes,
            keywords,
            showAll,
            hubId,
            limit
        }
        return this.httpClient.post(this.globalsService.apiUrl + '/rings/all', data).pipe(
            map((response: any) => {
                return response.rows
            })
        )
    }

    pendings(userId) {
        return this.httpClient.get(this.globalsService.apiUrl + '/rings/pendings/' + userId).pipe(
            map((response: any) => {
                return response.rows
            })
        )
    }

    add(newRing) {
        const data = {
            newGroup: newRing,
            referentId: this.userService.data.id
        }
        return this.httpClient.post(this.globalsService.apiUrl + '/rings/add', data).pipe(
            map((response: any) => {
                const rows = this.globalsService.renameEmptyColumn(response.rows)
                response.rows = rows
                return response
            })
        )
    }

    update(group) {
        return this.httpClient.patch(this.globalsService.apiUrl + '/ring', group).pipe(
            map((response: any) => {
                return response
            })
        )
    }

    delete(ring) {
        return this.httpClient.delete(this.globalsService.apiUrl + '/rings/' + ring.R.id).pipe(
            map((response: any) => {
                return response
            })
        )
    }

    invite(ring, profile) {
        const data = {
            ringId: ring.id,
            profileId: profile.id
        }
        console.log(JSON.stringify(data))
        return this.httpClient.put(this.globalsService.apiUrl + '/ring/invite', data).pipe(
            map((response: any) => {
                return response
            })
        )
    }

    disinvite(groupId, profileId) {
        return this.httpClient
            .delete(this.globalsService.apiUrl + '/ring/disinvite/' + groupId + '/' + profileId)
            .pipe(
                map((response: any) => {
                    return response
                })
            )
    }

    members(groupId) {
        return this.httpClient.get(this.globalsService.apiUrl + '/ring/members/' + groupId).pipe(
            map((response: any) => {
                return response.rows
            })
        )
    }

    membersCompanies(groupId) {
        return this.httpClient
            .get(this.globalsService.apiUrl + '/ring/members/companies/' + groupId)
            .pipe(
                map((response: any) => {
                    return response.rows
                })
            )
    }

    membersNested(ringId) {
        return this.httpClient
            .get(this.globalsService.apiUrl + '/ring/members/nested/' + ringId)
            .pipe(
                map((response: any) => {
                    return this.globalsService.renameEmptyColumn(response.rows)
                })
            )
    }

    membersWaiting(groupId) {
        return this.httpClient
            .get(this.globalsService.apiUrl + '/ring/members/waiting/' + groupId)
            .pipe(
                map((response: any) => {
                    return response.rows
                })
            )
    }

    membersWaitingNested(groupId) {
        return this.httpClient
            .get(this.globalsService.apiUrl + '/ring/members/waiting/nested/' + groupId)
            .pipe(
                map((response: any) => {
                    return this.globalsService.renameEmptyColumn(response.rows)
                })
            )
    }

    acceptInvitation(groupId, profileId) {
        const data = {
            groupId,
            profileId
        }
        return this.httpClient
            .patch(this.globalsService.apiUrl + '/ring/invitation/accept', data)
            .pipe(
                map((response: any) => {
                    return response
                })
            )
    }

    removeMember(groupId, profileId) {
        // var data = {
        //     groupId: groupId,
        //     profileId: profileId
        // }
        return this.httpClient.delete(this.globalsService.apiUrl + '/ring/removeMember').pipe(
            map((response: any) => {
                return response
            })
        )
    }

    missions(groupId) {
        return this.httpClient.get(this.globalsService.apiUrl + '/ring/missions/' + groupId).pipe(
            map((response: any) => {
                return response.rows
            })
        )
    }

    missionsNested(groupId, userId?) {
        let theUserId = null
        userId ? (theUserId = userId) : (theUserId = this.userService.data.id)
        return this.httpClient
            .get(this.globalsService.apiUrl + `/ring/missions/nested/${groupId}/${theUserId}`)
            .pipe(
                map((response: any) => {
                    return this.globalsService.renameEmptyColumn(response.rows)
                })
            )
    }

    join(ringId, profileId) {
        const data = {
            ringId,
            profileId
        }
        return this.httpClient.put(this.globalsService.apiUrl + '/ring/join', data).pipe(
            map((response: any) => {
                return response
            })
        )
    }

    clearPhoto(ringId) {
        const data = {
            ringId,
            filename: ringId + '.jpg'
        }
        return this.httpClient.patch(this.globalsService.apiUrl + '/ring/photo', data).pipe(
            map((response: any) => {
                return response
            })
        )
    }

    swipe(ringId, choice) {
        const data = {
            userId: this.userService.data.id,
            ringId,
            choice
        }
        return this.httpClient.put(this.globalsService.apiUrl + '/ring/swipe', data).pipe(
            map((response: any) => {
                return response
            })
        )
    }

    allFromChannel(channelId) {
        return this.httpClient.get(this.globalsService.apiUrl + `/rings/channel/${channelId}`)
    }
}
