import { Injectable } from '@angular/core'
import { environment } from '../../environments/environment'

@Injectable({
    providedIn: 'root'
})
export class GlobalsService {
    // apiUrl = 'https://api.missioon.com'
    apiUrl = environment.apiEndpoint
    pushUrl = 'https://push.missioon.com'
    imgProfilesFolder = 'https://api.missioon.com/img_profiles/'
    imgCompaniesFolder = 'https://api.missioon.com/img_companies/'
    logosCompaniesFolder = 'https://api.missioon.com/logos_companies/'
    imgChannelsFolder = 'https://api.missioon.com/img_channels/'
    imgRingsFolder = 'https://api.missioon.com/img_rings/'
    imgMissionsFolder = 'https://api.missioon.com/img_missions/'
    webAppUrl = 'https://share.missioon.com'
    zones = [
        { id: 0, label: '' },
        { id: 1, label: 'Toute la France' },
        { id: 2, label: 'Où, plus précisément ?' },
        { id: 3, label: 'Autour de moi' }
    ]

    public renameEmptyColumn(array) {
        const res = array
        res.map(object => {
            // tslint:disable-next-line: prefer-object-spread
            delete Object.assign(object, { ['CALC']: object[''] })['']
        })
        return res
    }

    public parseJson(data) {
        if (!data) {
            return null
        }
        return JSON.parse(data)
    }
}
