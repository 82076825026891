import { Component } from '@angular/core'
import {
    NavController,
    NavParams,
    ToastController,
    LoadingController,
    ModalController
} from '@ionic/angular'
import { ProfilesService } from 'src/app/services/profiles.service'
import { RingsService } from 'src/app/services/rings.service'
import { UserService } from 'src/app/services/user.service'
import { PushService } from 'src/app/services/push.service'
import { GlobalsService } from 'src/app/services/globals.service'

@Component({
    selector: 'app-ring-invite',
    templateUrl: './ring-invite.page.html',
    styleUrls: ['./ring-invite.page.scss']
})
export class RingInvitePage {
    group: any = {}
    profiles: any = []
    invitations: any = []
    searchName: string = null

    constructor(
        public navCtrl: NavController,
        public navParams: NavParams,
        private profilesService: ProfilesService,
        private ringsSrv: RingsService,
        private userService: UserService,
        private pushService: PushService,
        private toastCtrl: ToastController,
        public globalsSrv: GlobalsService,
        private loadingCtrl: LoadingController,
        private modalCtrl: ModalController
    ) {}

    ionViewWillEnter() {
        this.profiles = []
        this.group = this.ringsSrv.currentRing.R
        console.log('TCL: RingInvitePage -> this.group', this.group)
    }

    onSearch(searchName) {
        if (searchName.length > 2) {
            this.profilesService
                .search(this.group.id, searchName)
                .subscribe(res => (this.profiles = res))
        } else {
            this.profiles = []
        }
    }

    async onInvite(profile, index) {
        const loading = await this.loadingCtrl.create({
            message: 'Invitation en cours...'
        })
        await loading.present()
        this.ringsSrv.invite(this.group, profile).subscribe(
            () => {
                console.warn('3')
                const sender =
                    this.userService.data.firstname +
                    ' ' +
                    this.userService.data.lastname
                this.pushService
                    .sendToFCM(
                        'Cercles',
                        `Félicitations ! Vous avez été invité par ${sender}.`,
                        profile.token,
                        'Groups',
                        this.userService.data.id,
                        this.group.id,
                        null,
                        null
                    )
                    .subscribe(async () => {
                        console.warn('4')
                        loading.dismiss()
                        this.profiles.splice(index, 1)
                        const toast = await this.toastCtrl.create({
                            message: 'Invitation envoyée',
                            duration: 1500
                        })
                        toast.present()
                        this.profiles = []
                        this.ringsSrv.currentRing.CALC.members_waiting++
                    })
            },
            err => {
                loading.dismiss()
                console.error(JSON.stringify(err))
            }
        )
    }

    onClose() {
        this.ringsSrv
            .membersWaiting(this.group.id)
            .subscribe(rows => this.modalCtrl.dismiss(rows))
    }
}
