import { Injectable } from '@angular/core'
import { SearchParams } from '../models/search-params'

// interface MissionData {
//     M: {
//         details: ''
//     }
//     U?: {}
//     MP?: {}
//     FT?: {}
//     MR?: {}
//     R?: {}
//     S?: {}
//     CALC?: {}
// }

export interface Tag {
    display: string
    value: string
}
interface SearchOptions {
    tags_array: Tag[]
    keywords: string
    zone_id: number
    radius: number
    cities_json: []
    latitude?: number
    longitude?: number
}

@Injectable({
    providedIn: 'root'
})
export class SessionService {
    public data: any = {}
    public unreadedNotifsCount: number
    public currentProfile: any = {}
    public currentCertifications: any = []
    public currentCompany: any = {}
    public currentChannel: any = {}
    public currentProduct: any = {}
    public currentChannelTheme: any = {}
    public pageFrom: string
    public missionScope: any
    public chooseProfile: boolean
    public isSearchFromModalSend: any
    public activitiesIds: any
    public services: any = []
    public channels: any = []
    public rings: any = []
    public companies: any = []
    public userRings: any = []
    public otherRings: any = []
    public currentLatitude = 0
    public currentLongitude = 0
    public searchOptions: SearchOptions
    public searchParams: SearchParams = {
        keywords: '',
        pipes: '',
        activitiesTagsIds: ''
    }
    public searchResults: any = [] // pour les cartes Google

    constructor() {}
}
