import { HttpClient } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { Plugins } from '@capacitor/core'
import { Facebook } from '@ionic-native/facebook/ngx'
// import { GooglePlus } from '@ionic-native/google-plus/ngx'
import { map } from 'rxjs/operators'
import { GlobalsService } from './globals.service'
import { UserService } from './user.service'

const { Storage } = Plugins

@Injectable({
    providedIn: 'root'
})
export class AuthService {
    constructor(
        public httpClient: HttpClient,
        private globals: GlobalsService,
        private userSrv: UserService,
        private facebook: Facebook
    ) // private google: GooglePlus
    {}

    login(email, password) {
        const credentials = {
            email,
            password
        }
        return this.httpClient
            .post(this.globals.apiUrl + '/login', credentials)
            .pipe(
                map((response: any) => {
                    return response
                })
            )
    }

    getDataFromEmailAndNetwork(email, network) {
        return this.httpClient
            .get(
                this.globals.apiUrl +
                    '/profileFromEmailAndNetwork/' +
                    email +
                    '/' +
                    network
            )
            .pipe(
                map((response: any) => {
                    return response
                })
            )
    }

    reauthenticate(jwt) {
        const credentials = {
            token: jwt
        }
        return this.httpClient
            .post(this.globals.apiUrl + '/checkJwt', credentials)
            .pipe(
                map((response: any) => {
                    return response
                })
            )
    }

    // createRequestOptions() {
    //     const authorizationHeader = new Headers({
    //         Authorization: 'Bearer ' + this.userSrv.jwt
    //     })
    //     return new RequestOptions({ headers: authorizationHeader })
    // }

    // createNewRequestOptions() {
    //     const headers = new HttpHeaders().set('Authorization', 'Bearer ' + this.userSrv.jwt)
    //     return { headers }
    // }

    logout() {
        this.userSrv.isConnected = false
        if (this.userSrv.data.network == 'facebook') {
            this.facebook.logout()
        } else if (this.userSrv.data.network == 'google') {
            // this.google.logout()
        } else if (this.userSrv.data.network == 'linkedin') {
            // this.linkedin.logout()
        }
        this.userSrv.reset()
        this.userSrv.setJwt = null
        Storage.clear()
    }
}
