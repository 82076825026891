import { HttpClient, HttpHeaders } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { map } from 'rxjs/operators'
import { GlobalsService } from './globals.service'
import { NotificationsService } from './notifications.service'

@Injectable({
    providedIn: 'root'
})
export class PushService {
    constructor(
        private httpClient: HttpClient,
        private globalsService: GlobalsService,
        private notificationsService: NotificationsService
    ) {}

    saveToken(userId, token) {
        const data = {
            userId,
            token
        }
        return this.httpClient
            .post(this.globalsService.apiUrl + '/push/token', data)
            .pipe(
                map((response: any) => {
                    return response
                })
            )
    }

    updateToken(userId, token) {
        return this.httpClient
            .patch(
                this.globalsService.apiUrl +
                    '/push/token/' +
                    userId +
                    '/' +
                    token,
                null
            )
            .pipe(
                map((response: any) => {
                    return response
                })
            )
    }

    clearToken(userId) {
        const data = {
            userId
        }
        return this.httpClient
            .patch(this.globalsService.apiUrl + '/push/token/clear', data)
            .pipe(
                map((response: any) => {
                    return response
                })
            )
    }

    sendFromArray(dataForPush) {
        return this.httpClient
            .post(this.globalsService.pushUrl + '/send', dataForPush)
            .pipe(
                map((response: any) => {
                    return response
                })
            )
    }

    sendToFollowers(dataForPush) {
        return this.httpClient
            .post(this.globalsService.pushUrl + '/send/followers', dataForPush)
            .pipe(
                map((response: any) => {
                    return response
                })
            )
    }

    sendToGroups(dataForPush) {
        return this.httpClient
            .post(this.globalsService.pushUrl + '/send/group', dataForPush)
            .pipe(
                map((response: any) => {
                    return response
                })
            )
    }

    sendToFCM(from, message, to, service, senderId, serviceId, roomName, toId) {
        // console.log(`from ------- > ${from}`)
        // console.log(`message ------- > ${message}`)
        // console.log(`to ------- > ${to}`)
        // console.log(`service ------- > ${service}`)
        // console.log(`senderId ------- > ${senderId}`)
        // console.log(`serviceId ------- > ${serviceId}`)
        // console.log(`roomName ------- > ${roomName}`)
        // console.log(`toId ------- > ${toId}`)
        const dataForPush = {
            from,
            message,
            to,
            service,
            senderId,
            serviceId,
            roomName,
            toId
        }
        return this.httpClient
            .post(this.globalsService.pushUrl + '/send/fcm', dataForPush)
            .pipe(
                map((response: any) => {
                    this.notificationsService
                        .add(
                            dataForPush.from,
                            dataForPush.message,
                            dataForPush.service,
                            dataForPush.serviceId,
                            dataForPush.senderId,
                            dataForPush.roomName,
                            dataForPush.toId
                        )
                        .subscribe()
                    return response
                })
            )
    }

    // send(from, message, to, service, senderId, serviceId, roomName, toId) {
    //     console.log(`from ------- > ${from}`)
    //     console.log(`message ------- > ${message}`)
    //     console.log(`to ------- > ${to}`)
    //     console.log(`service ------- > ${service}`)
    //     console.log(`senderId ------- > ${senderId}`)
    //     console.log(`serviceId ------- > ${serviceId}`)
    //     console.log(`roomName ------- > ${roomName}`)
    //     console.log(`toId ------- > ${toId}`)
    //     return this.notificationsService.all(toId).pipe(
    //         map((response: any) => {
    //             const body = {
    //                 notification: {
    //                     title: from,
    //                     body: message,
    //                     sound: 'default',
    //                     badge: response.length + 1,
    //                     icon: 'fcm_push_icon'
    //                 },
    //                 data: {
    //                     param1: from,
    //                     param2: message,
    //                     service,
    //                     senderId,
    //                     serviceId,
    //                     room: roomName
    //                 },
    //                 to,
    //                 priority: 'high',
    //                 content_available: true
    //             }

    //             this.httpClient
    //                 .post('https://fcm.googleapis.com/fcm/send', body, {
    //                     headers: new HttpHeaders()
    //                         .set('Content-Type', 'application/json')
    //                         .set(
    //                             'Authorization',
    //                             'key=AAAAzhd9j84:APA91bGzPfG9clTK2aqPrOq8em9ik0xSICuGfru_fqSMUdIoJZgE6xZssj1YLNagj_tCf17zt_K19-o5rUKeR0u7BMktLt6UIqNANxIcG2m27IioTSIeDroM0sZ3-Z-vv86ll3G9v-xx'
    //                         )
    //                 })
    //                 .subscribe(
    //                     res => {
    //                         console.log(`------- > notification envoyée`)
    //                         this.notificationsService
    //                             .add(
    //                                 from,
    //                                 message,
    //                                 service,
    //                                 serviceId,
    //                                 senderId,
    //                                 roomName,
    //                                 toId
    //                             )
    //                             .subscribe(
    //                                 res => console.log(res),
    //                                 err => console.log(err)
    //                             )
    //                     },
    //                     err => console.error('ERROR : ', err)
    //                 )
    //         })
    //     )
    // }
}
