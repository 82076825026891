// import * as firebase from "firebase";

export const FIREBASE_CONFIG = {
    apiKey: 'AIzaSyC9MkS5LWgENB146E-z4lyh1g1CGS9rVhA',
    authDomain: 'mission-11c14.firebaseapp.com',
    databaseURL: 'https://mission-11c14.firebaseio.com',
    projectId: 'mission-11c14',
    storageBucket: 'mission-11c14.appspot.com',
    messagingSenderId: '885157367758'
}

// export default !firebase.apps.length ? firebase.initializeApp(FIREBASE_CONFIG) : firebase.app();