import { map } from 'rxjs/operators'
import { Injectable } from '@angular/core'
import { GlobalsService } from './globals.service'
import { HttpClient } from '@angular/common/http'

@Injectable({
    providedIn: 'root'
})
export class CertificationsService {
    constructor(private httpClient: HttpClient, private globalsService: GlobalsService) {}

    add(userId, companyId, certifierId) {
        const data = {
            userId,
            companyId,
            certifierId
        }
        return this.httpClient.put(this.globalsService.apiUrl + '/certifications/add', data).pipe(
            map((response: any) => {
                return response
            })
        )
    }

    allForUser(userId) {
        return this.httpClient
            .get(this.globalsService.apiUrl + `/certifications/user/${userId}`)
            .pipe(
                map((response: any) => {
                    // return response.rows
                    return this.globalsService.renameEmptyColumn(response.rows)
                })
            )
    }

    delete(certificationId) {
        return this.httpClient
            .delete(this.globalsService.apiUrl + `/certifications/${certificationId}`)
            .pipe(
                map((response: any) => {
                    return response
                })
            )
    }
}
