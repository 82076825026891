import { Component, OnInit } from '@angular/core'
import { mergeMap } from 'rxjs/operators'

import { Router } from '@angular/router'
import {
    Plugins,
    PushNotification,
    PushNotificationActionPerformed,
    PushNotificationToken
} from '@capacitor/core'
import { Badge } from '@ionic-native/badge/ngx'
import { Dialogs } from '@ionic-native/dialogs/ngx'
// import { Firebase } from '@ionic-native/firebase/ngx'
import { NavController, Platform, ToastController } from '@ionic/angular'
import { initializeApp } from 'firebase/app'
import * as moment from 'moment'
import { FIREBASE_CONFIG } from './firebase.config'
import { Theme } from './models/models'
import { Preferences } from './models/preferences'
import { AuthService } from './services/auth.service'
import { ChannelsService } from './services/channels.service'
import { DeviceService } from './services/device.service'
import { EventsService } from './services/events.service'
import { GlobalsService } from './services/globals.service'
import { MissionsService } from './services/missions.service'
import { NotificationsService } from './services/notifications.service'
import { ProfilesService } from './services/profiles.service'
import { PushService } from './services/push.service'
import { SessionService } from './services/session.service'
import { UserService } from './services/user.service'

const { PushNotifications, SplashScreen, Storage } = Plugins

@Component({
    selector: 'app-root',
    templateUrl: 'app.component.html'
})
export class AppComponent implements OnInit {
    imgProfilesFolder: string = null
    channelsPath: string = null
    nav: NavController
    network = undefined
    user: any = {}
    isConnected = false
    // companies: any = []
    // currentProfile = 0
    // selectedTheme: String;

    theme: Theme = {
        logo: null,
        color_foreground: null,
        color_background: null,
        color_dark: null,
        color_light: null,
        color_calm: null,
        color_primary: null,
        color_danger: null
    }
    notifications: any = []
    deviceInfo

    constructor(
        private platform: Platform,
        private userService: UserService,
        private profilesService: ProfilesService,
        private pushService: PushService,
        // private firebase: Firebase,
        private notifsSrv: NotificationsService,
        private badge: Badge,
        private missionsSrv: MissionsService,
        private dialogs: Dialogs,
        private toastCtrl: ToastController,
        private globals: GlobalsService,
        private auth: AuthService,
        // private companiesService: CompaniesService,
        // private keyboard: Keyboard,
        private channelPrv: ChannelsService,
        private navCtrl: NavController,
        private router: Router,
        private sessionSrv: SessionService,
        private eventsSrv: EventsService,
        private deviceSrv: DeviceService
    ) {
        this.channelsPath = this.globals.imgChannelsFolder
        this.initializeApp()
    }

    async ngOnInit() {
        this.deviceInfo = await this.deviceSrv.getInfos()
        if (this.deviceInfo.platform != 'web') {
            // Request permission to use push notifications
            // iOS will prompt user and return if they granted permission or not
            // Android will just grant without prompting
            PushNotifications.requestPermission().then(result => {
                if (result.granted) {
                    // Register with Apple / Google to receive push via APNS/FCM
                    PushNotifications.register()
                } else {
                    // Show some error
                }
            })

            // On success, we should be able to receive notifications
            PushNotifications.addListener(
                'registration',
                (token: PushNotificationToken) => {
                    this.userService.fcmToken = token.value
                }
            )

            // Some issue with our setup and push will not work
            // PushNotifications.addListener('registrationError', (error: any) => {
            //     alert('Error on registration: ' + JSON.stringify(error))
            // })

            // Show us the notification payload if the app is open on our device
            PushNotifications.addListener(
                'pushNotificationReceived',
                async (notification: PushNotification) => {
                    if (this.platform.is('android')) {
                        this.notifsSrv
                            .all(this.userService.data.id)
                            .subscribe(res => {
                                if (this.deviceInfo.platform != 'web') {
                                    this.badge.set(res.length)
                                }
                            })
                    }
                    if (this.deviceInfo.platform != 'web') {
                        this.badge.increase(1)
                    }
                    // const currentView = this.nav.getActive().name
                    const currentUrl: string = this.router.url
                    if (
                        currentUrl == '/messages' ||
                        currentUrl ==
                            '/chat' /*&& (data.service != 'Mission' || data.param1 != 'Mission') && (data.service != 'Candidature' || data.param1 != 'Candidature') && (data.service != 'Profile' || data.param1 != 'Profile') && (data.service != 'Groups') || data.param1 != 'Groups'*/
                    ) {
                        this.dialogs.beep(1)
                    } else {
                        this.dialogs.beep(1)
                        this.sessionSrv.unreadedNotifsCount =
                            this.sessionSrv.unreadedNotifsCount + 1
                        this.eventsSrv.publish(
                            'notifications:count',
                            this.sessionSrv.unreadedNotifsCount
                        )
                        if (this.platform.is('android')) {
                            const toast = await this.toastCtrl.create({
                                message:
                                    notification.data.param1 +
                                    ' : ' +
                                    notification.data.param2,
                                buttons: [
                                    {
                                        text: 'Fermer',
                                        role: 'cancel'
                                    }
                                ],
                                duration: 5000,
                                position: 'top'
                            })
                            toast.present()
                        }
                    }
                }
            )

            // Method called when tapping on a notification
            PushNotifications.addListener(
                'pushNotificationActionPerformed',
                (notification: PushNotificationActionPerformed) => {
                    if (this.platform.is('android')) {
                        this.notifsSrv
                            .all(this.userService.data.id)
                            .subscribe(res => {
                                if (this.deviceInfo.platform != 'web') {
                                    this.badge.set(res.length)
                                }
                            })
                    }
                    this.onNotification(notification.notification.data)
                }
            )
        }
    }

    checkDarkTheme() {
        const prefersDark = window.matchMedia('(prefers-color-scheme: dark)')
        document.body.classList.toggle('dark', prefersDark.matches)
    }

    async initializeApp() {
        // this.checkDarkTheme()
        try {
            initializeApp(FIREBASE_CONFIG)
        } catch (error) {}
        this.platform.resume.subscribe(() => {
            // this.checkDarkTheme()
            if (this.userService.isConnected) {
                this.profilesService
                    .updateLastConnection(this.userService.data.id)
                    .subscribe()
            }
        })
        // this.keyboard.hideFormAccessoryBar(false)
        this.eventsSrv.subscribe('user:created', () => {
            this.user = this.userService.getData()
        })

        this.eventsSrv.subscribe('user:login', () => {
            this.isConnected = true
            // TODO: gestion du thème du channel sélectionné
        })

        this.eventsSrv.subscribe('user:logout', () => {
            this.isConnected = false
        })

        this.eventsSrv.subscribe('notifications:count', (count: number) => {
            if (this.deviceInfo.platform != 'web') {
                this.badge.set(count)
            }
        })

        //   events.subscribe('companies:update', () => {
        //     this.companiesService.allForUser(this.userService.data.id).subscribe(
        //       res => {
        //         this.companies = res
        //         let exists: boolean = false
        //         this.companies.map(
        //           company => {
        //             if (company.id === this.currentProfile) {
        //               exists = true
        //             }
        //           }
        //         )
        //         if (!exists) {
        //           this.currentProfile = 0
        //           this.profilesService.updateCurrentProfile(this.currentProfile).subscribe()
        //         }
        //       }
        //     )
        // })

        const result = await Storage.get({ key: 'activityParams' })
        if (result.value) {
            this.userService.activityParams = JSON.parse(result.value)
        } else {
            this.userService.activityParams = {
                missionsIncoming: true,
                missionsInProgress: true,
                missionsWon: false,
                missionsCompleted: false,
                candidatures: false
            }
        }

        this.imgProfilesFolder = this.globals.imgProfilesFolder

        moment.locale('fr-fr')

        const resultToken = await Storage.get({ key: 'jwt' })
        const jwt = resultToken.value

        if (jwt) {
            this.auth.reauthenticate(jwt).subscribe(res => {
                if (res.success) {
                    this.userService.isConnected = true
                    this.userService.setData(res.body)

                    // this.userService.setJwt(jwt)
                    this.userService.jwt = jwt
                    this.user = this.userService.getData()

                    this.profilesService
                        .getPreferences()
                        .subscribe((prefs: Preferences) => {
                            this.userService.prefs = prefs
                            this.userService.searchOptions.radius = Number(
                                prefs.search_radius
                            )
                            this.userService.searchOptions.showAll = Boolean(
                                prefs.showAll
                            )
                            this.userService.searchOptions.remember = Boolean(
                                prefs.search_remember
                            )
                        })

                    // this.companiesService.allForUser(this.userService.data.id)
                    // .subscribe(
                    //   res => {
                    //     this.companies = res
                    //     this.currentProfile = this.userService.data.currentProfile
                    //   }
                    // )

                    this.profilesService
                        .getAllTagsFromUser()
                        .pipe(
                            mergeMap(data => {
                                this.userService.setTags(data.rows)
                                return this.pushService.updateToken(
                                    this.userService.data.id,
                                    this.userService.fcmToken
                                )
                            })
                        )
                        .subscribe(
                            data => {
                                if (
                                    this.userService.isConnected == true &&
                                    this.userService.data.confirmed == 1
                                ) {
                                    this.profilesService
                                        .updateLastConnection(
                                            this.userService.data.id
                                        )
                                        .subscribe(() => {
                                            // if (
                                            //     this.userService.data.latitude &&
                                            //     this.userService.data.longitude
                                            // ) {
                                            this.eventsSrv.publish(
                                                'user:login',
                                                {}
                                            )
                                            const channelId = this.userService
                                                .data.rootChannelId
                                            if (channelId == null) {
                                                // this.navCtrl.navigateRoot(
                                                //     '/tabs/missioon'
                                                // )
                                                this.notifsSrv
                                                    .all(
                                                        this.userService.data.id
                                                    )
                                                    .subscribe(res => {
                                                        this.notifications = res
                                                        const unread = this.notifications.filter(
                                                            item => {
                                                                return (
                                                                    item.is_read ==
                                                                    0
                                                                )
                                                            }
                                                        )
                                                        this.eventsSrv.publish(
                                                            'notifications:count',
                                                            unread.length
                                                        )
                                                        this.navCtrl.navigateRoot(
                                                            '/tabs/missioon'
                                                        )
                                                    })
                                            } else {
                                                this.channelPrv
                                                    .byId(channelId)
                                                    .subscribe(
                                                        res =>
                                                            this.router.navigate(
                                                                ['/channel'],
                                                                {
                                                                    queryParams: {
                                                                        channel:
                                                                            res.channel
                                                                    }
                                                                }
                                                            )
                                                        // this.nav.setRoot(
                                                        //     'ChannelPage',
                                                        //     {
                                                        //         channel: res.channel
                                                        //     }
                                                        // )
                                                    )
                                            }
                                            // } else {
                                            //     this.nav.setRoot('UserAddressPage')
                                            // }
                                        })
                                }
                            },
                            error => {
                                console.log(JSON.stringify(error))
                            }
                        )
                } else {
                    console.log(JSON.stringify(res))
                }
            })
        }

        SplashScreen.hide()
    }

    onMissioon() {
        this.profilesService.setRootChannel(null).subscribe(() => {
            this.userService.data.rootChannelId = null
            this.userService.data.rootChannelName = null
        })
    }

    private onNotification(data) {
        if (data.service == 'Profile' || data.param1 == 'Profile') {
            this.profilesService.byId(data.senderId).subscribe(res => {
                // this.nav.push('ResultatsProfilePage', { profile: res.rows[0] })
                this.router.navigate(['/results-profile'], {
                    queryParams: { profile: res.rows[0] }
                })
            })
        } else if (
            data.service == 'Mission' ||
            data.service == 'Candidature' ||
            data.param1 == 'Mission' ||
            data.param1 == 'Candidature'
        ) {
            this.missionsSrv.byId(data.serviceId).subscribe(missionData => {
                // this.nav.push('ResultatsMissionPage', { mission: missions[0] })

                // this.nav.push('ResultatsMissionPage', {
                //     missionData: missionData,
                //     mission: missionData.M,
                //     from: 'home'
                // })
                let data = null
                this.missionsSrv
                    .byIdNested(data.serviceId)
                    .pipe(
                        mergeMap(rows => {
                            data = rows[0]
                            return this.profilesService.byId(data.U.id)
                        })
                    )
                    .subscribe(res => {
                        // this.nav.push('MissionOfferPage', {
                        //     missionData: data,
                        //     profile: res.rows[0],
                        //     mission: data.M,
                        //     goal: 'offer'
                        // })
                        this.router.navigate(['mission-offer'], {
                            queryParams: {
                                missionData: data,
                                profile: res.rows[0],
                                mission: data.M,
                                goal: 'offer'
                            }
                        })
                    })
            })
        } else if (data.service == 'Follow' || data.param1 == 'Follow') {
            // this.nav.setRoot(this.networkPage)
            this.navCtrl.navigateRoot('network')
        } else if (data.service == 'Chat' || data.param1 == 'Chat') {
            const room = data.room
            const ownerId = room.replace(String(this.userService.data.id), '')
            this.profilesService.byId(ownerId).subscribe(res => {
                // let profile = res.rows[0]
                // profile.owner_id = owner_id
                // this.chatSrv.openChat(data.room, this.userService.data, profile).subscribe(() => {
                //     this.app.getRootNavs()[0].setRoot('MessagesPage', { profile: profile })
                // })

                // this.nav.push('MissionOfferPage', {
                //     missionData: null,
                //     profile: res.rows[0],
                //     mission: null,
                //     goal: 'chat'
                // })
                this.router.navigate([
                    'mission-offer',
                    {
                        queryParams: {
                            missionData: null,
                            profile: res.rows[0],
                            mission: null,
                            goal: 'chat'
                        }
                    }
                ])
            })
        } else if (data.service == 'Groups' || data.param1 == 'Groups') {
            // this.nav.setRoot(this.ringsPage)
            this.navCtrl.navigateRoot('rings')
        }
    }
}
