import { Component } from '@angular/core'
import { CameraSource } from '@capacitor/core'
import { NavController, PopoverController } from '@ionic/angular'

@Component({
    selector: 'app-choose-device-photos',
    templateUrl: './choose-device-photos.page.html',
    styleUrls: ['./choose-device-photos.page.scss']
})
export class ChooseDevicePhotosPage {
    cameraSrc = CameraSource

    constructor(
        public navCtrl: NavController,
        private popOver: PopoverController
    ) {}

    onChange(source: CameraSource) {
        this.popOver.dismiss(source)
    }
}
