import { Injectable } from '@angular/core'
import { SwUpdate } from '@angular/service-worker'
import { AlertController } from '@ionic/angular'

@Injectable({
    providedIn: 'root'
})
export class PwaService {
    promptEvent

    constructor(
        private swUpdate: SwUpdate,
        private alertCtrl: AlertController
    ) {
        swUpdate.available.subscribe(event => {
            if (this.askUserToUpdate()) {
                window.location.reload()
            }
        })
        window.addEventListener('beforeinstallprompt', event => {
            this.promptEvent = event
        })
    }

    async askUserToUpdate() {
        const alert = await this.alertCtrl.create({
            header: 'Êtes-vous sur ?',
            buttons: [
                {
                    text: 'Non',
                    role: 'cancel',
                    handler: () => {
                        return false
                    }
                },
                {
                    text: 'Oui',
                    handler: () => {
                        return true
                    }
                }
            ]
        })
        await alert.present()
    }
}
