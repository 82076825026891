import { HttpClient } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { map } from 'rxjs/operators'
import { GlobalsService } from './globals.service'
import { UserService } from './user.service'

@Injectable({
    providedIn: 'root'
})
export class ChatService {
    requestOptions = null

    constructor(
        private httpClient: HttpClient,
        private globalsService: GlobalsService,
        private userSrv: UserService
    ) {}

    all(userId) {
        return this.httpClient
            .get(this.globalsService.apiUrl + '/chats/all/' + userId)
            .pipe(
                map((response: any) => {
                    return response.rows
                })
            )
    }

    getChat(room) {
        return this.httpClient
            .get(this.globalsService.apiUrl + '/chat/' + room)
            .pipe(
                map((response: any) => {
                    return response
                })
            )
    }

    deleteChat(chatId) {
        return this.httpClient
            .delete(
                this.globalsService.apiUrl +
                    '/chat/' +
                    chatId +
                    '/' +
                    this.userSrv.data.id
            )
            .pipe(
                map((response: any) => {
                    return response
                })
            )
    }

    openChat(room, user, profile) {
        const data = {
            from: user.id,
            to: profile.owner_id,
            room
        }
        return this.httpClient
            .put(this.globalsService.apiUrl + '/chat/open', data)
            .pipe(
                map((response: any) => {
                    return response
                })
            )
    }

    openChat_v2(room, user, profile) {
        const data = {
            from: user.id,
            to: profile.id,
            room
        }
        return this.httpClient
            .put(this.globalsService.apiUrl + '/chat/open', data)
            .pipe(
                map((response: any) => {
                    return response
                })
            )
    }

    allMessages(room) {
        return this.httpClient
            .get(this.globalsService.apiUrl + '/chat/messages/' + room)
            .pipe(
                map((response: any) => {
                    return response.rows
                })
            )
    }

    addMessage(room, senderId, message) {
        const data = {
            room,
            senderId,
            message
        }
        return this.httpClient
            .put(this.globalsService.apiUrl + '/chat/addMessage', data)
            .pipe(
                map((response: any) => {
                    return response
                })
            )
    }

    toggleAllMessages(userId, profileId, status) {
        const data = {
            userId,
            profileId,
            status
        }
        return this.httpClient
            .patch(
                this.globalsService.apiUrl + '/chat/messages/toggle/all',
                data
            )
            .pipe(
                map((response: any) => {
                    return response
                })
            )
    }
}
