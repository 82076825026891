import { NgModule } from '@angular/core'
import { RouterModule, Routes } from '@angular/router'

const routes: Routes = [
    { path: '', loadChildren: () => import('./login/login.module').then(m => m.LoginPageModule) },
    { path: '', loadChildren: () => import('./tabs/tabs.module').then(m => m.TabsPageModule) },
    { path: 'access', loadChildren: () => import('./access/access.module').then(m => m.AccessPageModule) },
    {
        path: 'account',
        loadChildren: () => import('./account/account.module').then(m => m.AccountPageModule)
    },
    {
        path: 'achievements',
        loadChildren:
            () => import('./achievements/achievements.module').then(m => m.AchievementsPageModule)
    },
    {
        path: 'activation',
        loadChildren: () => import('./activation/activation.module').then(m => m.ActivationPageModule)
    },
    {
        path: 'activity',
        loadChildren: () =>
            import('./activity/activity.module').then(m => m.ActivityPageModule)
    },
    {
        path: 'activity-options',
        loadChildren:
            () => import('./activity/activity-options/activity-options.module').then(m => m.ActivityOptionsPageModule)
    },
    {
        path: 'around-me',
        loadChildren: () => import('./around-me/around-me.module').then(m => m.AroundMePageModule)
    },
    { path: 'book', loadChildren: () => import('./book/book.module').then(m => m.BookPageModule) },
    {
        path: 'book-results',
        loadChildren:
            () => import('./book/book-results/book-results.module').then(m => m.BookResultsPageModule)
    },
    {
        path: 'bookmark',
        loadChildren: () => import('./bookmark/bookmark.module').then(m => m.BookmarkPageModule)
    },
    {
        path: 'candidatures',
        loadChildren:
            () => import('./candidatures/candidatures.module').then(m => m.CandidaturesPageModule)
    },
    {
        path: 'channel',
        loadChildren: () => import('./channel/channel.module').then(m => m.ChannelPageModule)
    },
    {
        path: 'channel-admin',
        loadChildren:
            () => import('./channel/channel-admin/channel-admin.module').then(m => m.ChannelAdminPageModule)
    },
    {
        path: 'channel-feed',
        loadChildren: () => import('./channel-feed/channel-feed.module').then(m => m.ChannelFeedPageModule)
    },
    {
        path: 'channel-feeds',
        loadChildren:
            () => import('./channel-feeds/channel-feeds.module').then(m => m.ChannelFeedsPageModule)
    },
    {
        path: 'channel-youtube',
        loadChildren:
            () => import('./channel-youtube/channel-youtube.module').then(m => m.ChannelYoutubePageModule)
    },
    { path: 'choose', loadChildren: () => import('./choose/choose.module').then(m => m.ChoosePageModule) },
    {
        path: 'choose-profile',
        loadChildren:
            () => import('./choose-profile/choose-profile.module').then(m => m.ChooseProfilePageModule)
    },
    {
        path: 'companies',
        loadChildren: () => import('./companies/companies.module').then(m => m.CompaniesPageModule)
    },
    {
        path: 'company',
        loadChildren: () => import('./companies/company/company.module').then(m => m.CompanyPageModule)
    },
    {
        path: 'company-edit',
        loadChildren:
            () => import('./companies/company-edit/company-edit.module').then(m => m.CompanyEditPageModule)
    },
    {
        path: 'company-new',
        loadChildren:
            () => import('./companies/company-new/company-new.module').then(m => m.CompanyNewPageModule)
    },
    {
        path: 'document-new',
        loadChildren: () => import('./document-new/document-new.module').then(m => m.DocumentNewPageModule)
    },
    {
        path: 'documents',
        loadChildren: () => import('./documents/documents.module').then(m => m.DocumentsPageModule)
    },
    {
        path: 'documents-popover',
        loadChildren:
            () => import('./documents-popover/documents-popover.module').then(m => m.DocumentsPopoverPageModule)
    },
    {
        path: 'followers',
        loadChildren: () => import('./followers/followers.module').then(m => m.FollowersPageModule)
    },
    {
        path: 'get-photo',
        loadChildren: () => import('./get-photo/get-photo.module').then(m => m.GetPhotoPageModule)
    },
    { path: 'help', loadChildren: () => import('./help/help.module').then(m => m.HelpPageModule) },
    {
        path: 'hub-edit',
        loadChildren: () => import('./hub-edit/hub-edit.module').then(m => m.HubEditPageModule)
    },
    { path: 'hubs', loadChildren: () => import('./hubs/hubs.module').then(m => m.HubsPageModule) },
    { path: 'login', loadChildren: () => import('./login/login.module').then(m => m.LoginPageModule) },
    {
        path: 'messages',
        loadChildren: () => import('./messages/messages.module').then(m => m.MessagesPageModule)
    },
    {
        path: 'chat',
        loadChildren: () => import('./messages/chat/chat.module').then(m => m.ChatPageModule)
    },
    {
        path: 'mission-offer',
        loadChildren:
            () => import('./mission-offer/mission-offer.module').then(m => m.MissionOfferPageModule)
    },
    {
        path: 'apply-mission-with-message',
        loadChildren:
            () => import('./missions/apply-mission-with-message/apply-mission-with-message.module').then(m => m.ApplyMissionWithMessagePageModule)
    },
    {
        path: 'mission',
        loadChildren: () => import('./missions/mission/mission.module').then(m => m.MissionPageModule)
    },
    {
        path: 'mission-edit',
        loadChildren:
            () => import('./missions/mission-edit/mission-edit.module').then(m => m.MissionEditPageModule)
    },
    {
        path: 'mission-end',
        loadChildren:
            () => import('./missions/mission-end/mission-end.module').then(m => m.MissionEndPageModule)
    },
    {
        path: 'mission-needs',
        loadChildren:
            () => import('./missions/mission-needs/mission-needs.module').then(m => m.MissionNeedsPageModule)
    },
    {
        path: 'mission-new',
        loadChildren:
            () => import('./missions/mission-new/mission-new.module').then(m => m.MissionNewPageModule)
    },
    {
        path: 'mission-tags',
        loadChildren:
            () => import('./missions/mission-tags/mission-tags.module').then(m => m.MissionTagsPageModule)
    },
    {
        path: 'missions-won',
        loadChildren: () => import('./missions-won/missions-won.module').then(m => m.MissionsWonPageModule)
    },
    {
        path: 'missioon',
        loadChildren: () => import('./missioon/missioon.module').then(m => m.MissioonPageModule)
    },
    {
        path: 'network',
        loadChildren: () => import('./network/network.module').then(m => m.NetworkPageModule)
    },
    {
        path: 'follow-new-tags',
        loadChildren:
            () => import('./network/follow-new-tags/follow-new-tags.module').then(m => m.FollowNewTagsPageModule)
    },
    {
        path: 'network-followers',
        loadChildren:
            () => import('./network-followers/network-followers.module').then(m => m.NetworkFollowersPageModule)
    },
    {
        path: 'network-perso',
        loadChildren:
            () => import('./network-perso/network-perso.module').then(m => m.NetworkPersoPageModule)
    },
    {
        path: 'network-pro',
        loadChildren: () => import('./network-pro/network-pro.module').then(m => m.NetworkProPageModule)
    },
    {
        path: 'network-tabs',
        loadChildren: () => import('./network-tabs/network-tabs.module').then(m => m.NetworkTabsPageModule)
    },
    {
        path: 'password',
        loadChildren: () => import('./password/password.module').then(m => m.PasswordPageModule)
    },
    {
        path: 'change-password',
        loadChildren:
            () => import('./password/change-password/change-password.module').then(m => m.ChangePasswordPageModule)
    },
    {
        path: 'precisely',
        loadChildren: () => import('./precisely/precisely.module').then(m => m.PreciselyPageModule)
    },
    {
        path: 'preferences',
        loadChildren: () => import('./preferences/preferences.module').then(m => m.PreferencesPageModule)
    },
    {
        path: 'presentation',
        loadChildren:
            () => import('./presentation/presentation.module').then(m => m.PresentationPageModule)
    },
    {
        path: 'about-user',
        loadChildren:
            () => import('./profile/about-user/about-user.module').then(m => m.AboutUserPageModule)
    },
    {
        path: 'competence-new',
        loadChildren:
            () => import('./profile/competence-new/competence-new.module').then(m => m.CompetenceNewPageModule)
    },
    {
        path: 'contracts',
        loadChildren: () => import('./profile/contracts/contracts.module').then(m => m.ContractsPageModule)
    },
    {
        path: 'etat-civil',
        loadChildren:
            () => import('./profile/etat-civil/etat-civil.module').then(m => m.EtatCivilPageModule)
    },
    {
        path: 'languages',
        loadChildren: () => import('./profile/languages/languages.module').then(m => m.LanguagesPageModule)
    },
    {
        path: 'profile-photo',
        loadChildren:
            () => import('./profile/profile-photo/profile-photo.module').then(m => m.ProfilePhotoPageModule)
    },
    {
        path: 'skills',
        loadChildren: () => import('./profile/skills/skills.module').then(m => m.SkillsPageModule)
    },
    { path: 'tags', loadChildren: () => import('./profile/tags/tags.module').then(m => m.TagsPageModule) },
    {
        path: 'user-address',
        loadChildren:
            () => import('./profile/user-address/user-address.module').then(m => m.UserAddressPageModule)
    },
    {
        path: 'visibility-info',
        loadChildren:
            () => import('./profile/visibility-info/visibility-info.module').then(m => m.VisibilityInfoPageModule)
    },
    { path: 'radar', loadChildren: () => import('./radar/radar.module').then(m => m.RadarPageModule) },
    {
        path: 'radar-options',
        loadChildren:
            () => import('./radar/radar-options/radar-options.module').then(m => m.RadarOptionsPageModule)
    },
    { path: 'rating', loadChildren: () => import('./rating/rating.module').then(m => m.RatingPageModule) },
    {
        path: 'rating-details',
        loadChildren:
            () => import('./rating-details/rating-details.module').then(m => m.RatingDetailsPageModule)
    },
    {
        path: 'register',
        loadChildren: () => import('./register/register.module').then(m => m.RegisterPageModule)
    },
    {
        path: 'resultats-mission',
        loadChildren:
            () => import('./resultats-mission/resultats-mission.module').then(m => m.ResultatsMissionPageModule)
    },
    {
        path: 'resultats-profile',
        loadChildren:
            () => import('./resultats-profile/resultats-profile.module').then(m => m.ResultatsProfilePageModule)
    },
    {
        path: 'results',
        loadChildren: () => import('./results/results.module').then(m => m.ResultsPageModule)
    },
    {
        path: 'ring-image-edit',
        loadChildren:
            () => import('./ring-image-edit/ring-image-edit.module').then(m => m.RingImageEditPageModule)
    },
    { path: 'rings', loadChildren: () => import('./rings/rings.module').then(m => m.RingsPageModule) },
    { path: 'ring', loadChildren: () => import('./rings/ring/ring.module').then(m => m.RingPageModule) },
    {
        path: 'ring-edit',
        loadChildren: () => import('./rings/ring-edit/ring-edit.module').then(m => m.RingEditPageModule)
    },
    {
        path: 'ring-invite',
        loadChildren:
            () => import('./rings/ring-invite/ring-invite.module').then(m => m.RingInvitePageModule)
    },
    {
        path: 'ring-members',
        loadChildren:
            () => import('./rings/ring-members/ring-members.module').then(m => m.RingMembersPageModule)
    },
    {
        path: 'ring-missions',
        loadChildren:
            () => import('./rings/ring-missions/ring-missions.module').then(m => m.RingMissionsPageModule)
    },
    {
        path: 'ring-missioon',
        loadChildren:
            () => import('./rings/ring-missioon/ring-missioon.module').then(m => m.RingMissioonPageModule)
    },
    {
        path: 'ring-new',
        loadChildren: () => import('./rings/ring-new/ring-new.module').then(m => m.RingNewPageModule)
    },
    {
        path: 'search-tags',
        loadChildren: () => import('./search-tags/search-tags.module').then(m => m.SearchTagsPageModule)
    },
    {
        path: 'show-on-card',
        loadChildren: () => import('./show-on-card/show-on-card.module').then(m => m.ShowOnMapPageModule)
    },
    { path: 'stats', loadChildren: () => import('./stats/stats.module').then(m => m.StatsPageModule) },
    {
        path: 'stats-candidatures',
        loadChildren:
            () => import('./stats-candidatures/stats-candidatures.module').then(m => m.StatsCandidaturesPageModule)
    },
    {
        path: 'stats-missions',
        loadChildren:
            () => import('./stats-missions/stats-missions.module').then(m => m.StatsMissionsPageModule)
    },
    {
        path: 'subscriptions',
        loadChildren:
            () => import('./subscriptions/subscriptions.module').then(m => m.SubscriptionsPageModule)
    },
    {
        path: 'notifications-tab',
        loadChildren:
            () => import('./tabs/notifications-tab/notifications-tab.module').then(m => m.NotificationsTabPageModule)
    },
    {
        path: 'user-data',
        loadChildren: () => import('./user-data/user-data.module').then(m => m.UserDataPageModule)
    },
    {
        path: 'user-profile',
        loadChildren: () => import('./user-profile/user-profile.module').then(m => m.UserProfilePageModule)
    },
    { path: 'phones', loadChildren: () => import('./phones/phones.module').then(m => m.PhonesPageModule) },
    {
        path: 'website',
        loadChildren: () => import('./website/website.module').then(m => m.WebsitePageModule)
    },
    {
        path: 'welcome',
        loadChildren: () => import('./welcome/welcome.module').then(m => m.WelcomePageModule)
    },
    { path: 'when', loadChildren: () => import('./when/when.module').then(m => m.WhenPageModule) },
    { path: 'where', loadChildren: () => import('./where/where.module').then(m => m.WherePageModule) },
    {
        path: 'wordpress-article',
        loadChildren:
            () => import('./wordpress-article/wordpress-article.module').then(m => m.WordpressArticlePageModule)
    },
    {
        path: 'choose-device-photos',
        loadChildren:
            () => import('./choose-device-photos/choose-device-photos.module').then(m => m.ChooseDevicePhotosPageModule)
    },
    {
        path: 'search-options-popover',
        loadChildren:
            () => import('./search-options-popover/search-options-popover.module').then(m => m.SearchOptionsPopoverPageModule)
    },
    { path: 'themes', loadChildren: () => import('./themes/themes.module').then(m => m.ThemesPageModule) },
    {
        path: 'company-missions',
        loadChildren:
            () => import('./companies/company-missions/company-missions.module').then(m => m.CompanyMissionsPageModule)
    },
    {
        path: 'mission-companies',
        loadChildren:
            () => import('./missions/mission-companies/mission-companies.module').then(m => m.MissionCompaniesPageModule)
    },
    {
        path: 'certifications',
        loadChildren:
            () => import('./certifications/certifications.module').then(m => m.CertificationsPageModule)
    },
    {
        path: 'resultats-profile-missions',
        loadChildren:
            () => import('./resultats-profile-missions/resultats-profile-missions.module').then(m => m.ResultatsProfileMissionsPageModule)
    },
    {
        path: 'certification-new',
        loadChildren:
            () => import('./certifications/certification-new/certification-new.module').then(m => m.CertificationNewPageModule)
    },
    {
        path: 'mission-s', // CHECK:
        loadChildren:
            () => import('./missions/mission-types/mission-types.module').then(m => m.MissionTypesPageModule)
    },
    {
        path: 'user-channels',
        loadChildren:
            () => import('./user-channels/user-channels.module').then(m => m.UserChannelsPageModule)
    },
    {
        path: 'color-selector/:color',
        loadChildren:
            () => import('./color-selector/color-selector.module').then(m => m.ColorSelectorPageModule)
    },
    {
        path: 'channel-new',
        loadChildren: () => import('./channel-new/channel-new.module').then(m => m.ChannelNewPageModule)
    },
    {
        path: 'channel-products',
        loadChildren:
            () => import('./channel-products/channel-products.module').then(m => m.ChannelProductsPageModule)
    },
    {
        path: 'product-new',
        loadChildren: () => import('./product-new/product-new.module').then(m => m.ProductNewPageModule)
    },
    {
        path: 'ring-image',
        loadChildren: () => import('./rings/ring-image/ring-image.module').then(m => m.RingImagePageModule)
    },
    {
        path: 'mission-rings',
        loadChildren:
            () => import('./missions/mission-rings/mission-rings.module').then(m => m.MissionRingsPageModule)
    },
    {
        path: 'mission-documents',
        loadChildren:
            () => import('./missions/mission-documents/mission-documents.module').then(m => m.MissionDocumentsPageModule)
    },
    {
        path: 'search-where',
        loadChildren: () => import('./search-where/search-where.module').then(m => m.SearchWherePageModule)
    },
    { path: 'phones', loadChildren: () => import('./phones/phones.module').then(m => m.PhonesPageModule) }
]
@NgModule({
    imports: [RouterModule.forRoot(routes)],
    exports: [RouterModule]
})
export class AppRoutingModule {}
