import { Component } from '@angular/core'
import { NgForm } from '@angular/forms'
import { Plugins } from '@capacitor/core'
import {
    LoadingController,
    NavController,
    ToastController
} from '@ionic/angular'
import { AuthSocialsService } from '../services/auth-socials.service'
import { EventsService } from '../services/events.service'
import { ProfilesService } from '../services/profiles.service'
import { PushService } from '../services/push.service'
import { UserService } from '../services/user.service'
const { Browser } = Plugins

@Component({
    selector: 'app-register',
    templateUrl: './register.page.html',
    styleUrls: ['./register.page.scss']
})
export class RegisterPage {
    prefixes: any = []
    data = {
        prefixId: 1
    }
    // prefixId = '1'

    constructor(
        private navCtrl: NavController,
        private profilesService: ProfilesService,
        private userService: UserService,
        private toastCtrl: ToastController,
        private pushService: PushService,
        private eventsSrv: EventsService,
        private authSocialsSrv: AuthSocialsService,
        private loadingCtrl: LoadingController
    ) {}

    ionViewWillEnter() {
        this.profilesService
            .prefixes()
            .subscribe(res => (this.prefixes = res.rows))
    }

    async onRegister(form: NgForm) {
        if (form) {
            const loading = await this.loadingCtrl.create({
                message: 'Vérifications en cours...'
            })
            await loading.present()
            const data = {
                prefixId: '3',
                firstname: form.value.firstname,
                lastname: form.value.lastname,
                email: form.value.email,
                password: form.value.password,
                photo_url: '',
                confirmed: 0,
                network: 'mission'
            }
            this.profilesService.newAccount(data).subscribe(async data => {
                if (data.success) {
                    this.userService.jwt = data.jwt
                    this.pushService
                        .updateToken(data.body.id, this.userService.fcmToken)
                        .subscribe()
                    this.userService.setData(data.body)
                    this.eventsSrv.publish('user:created', {})
                    this.profilesService
                        .sendActivationEmail()
                        .subscribe(async data => {
                            if (data.success) {
                                loading.dismiss()
                                // this.viewCtrl.dismiss()
                                this.navCtrl.navigateRoot(['/activation'])
                                // this.navCtrl.setRoot('ActivationPage')
                            } else {
                                loading.dismiss()
                                const toast = await this.toastCtrl.create({
                                    message: `L'adresse e-mail semble incorrecte'.`,
                                    duration: 1500
                                })
                                toast.present()
                            }
                        })
                } else {
                    loading.dismiss()
                    const toast = await this.toastCtrl.create({
                        message: data.reason,
                        duration: 1500
                    })
                    toast.present()
                }
            })
        }
    }

    onFacebook() {
        this.authSocialsSrv.loginFacebook()
    }

    onGoogle() {
        this.authSocialsSrv.loginGoogle()
    }

    onLinkedIn() {
        this.authSocialsSrv.loginLinkedIn()
    }

    onRGPD() {
        Browser.open({ url: 'https://www.missioon.com/#/rgpd' })
    }

    onLogin() {
        this.navCtrl.navigateRoot('login')
    }
}
